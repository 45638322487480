import React from 'react';

import styled, { css } from 'styled-components';

import { Orientation } from '../../types';
import { PAGE_BREAK_POINT_800PX } from '../../types/breakpoints';

export const Paragraph = styled.p`
  text-align: left;
  margin-bottom: 21px;
  ${({ theme }) => theme.fontSize.B12};
  color: ${({ theme }) => theme.color.GREY_DARK};
`;

export const H1 = styled.h1`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  line-height: 24px;
  margin-bottom: 26px;
  @media only screen and (min-width: 640px) {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 48px;
  }
  margin-top: 56px;
`;

export const H2 = styled.h2`
  margin: 0 0 32px;
  color: ${({ theme }) => theme.color.GREY_DARKER};
  font-size: 22px;
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  line-height: 32px;
  @media only screen and (min-width: ${PAGE_BREAK_POINT_800PX}) {
    margin: 0 0 40px;
  }
`;

export const H3 = styled.h3`
  font-size: 14px;
  color: ${({ theme }) => theme.color.GREY_DARKER};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  margin-bottom: 12px;
`;

export const FormLayout = styled.div<{
  narrow: boolean;
  orientation?: Orientation;
}>`
  display: block;
  ${({ orientation, narrow, theme }) =>
    orientation === Orientation.HORIZONTAL &&
    css`
      @media screen and (min-width: 900px) {
        display: grid !important;
        grid-gap: ${narrow ? theme.spacing.M32 : '7%'};
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
      }
    `};
  ${({ narrow, orientation, theme }) =>
    narrow === true &&
    orientation !== Orientation.HORIZONTAL &&
    `
      max-width: 480px;
      padding: ${theme.spacing.M24} ${theme.spacing.M20} ${theme.spacing.L40};
      @media only screen and (min-width: 1004px) { 
        padding: ${theme.spacing.M24} 0 ${theme.spacing.L64};
      }
      margin-left: auto;
      margin-right: auto;
  `};
`;

export const ErrorMessage = styled.p`
  white-space: pre-line;
  margin-top: 8px;
  color: ${({ theme }) => theme.color.ERROR};
  font-size: 12px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FormField = styled(({ hidden, spacer, ...rest }) => (
  <div {...rest} />
))`
  margin-bottom: ${(props: any) =>
    props.hidden
      ? '0px'
      : props.spacer === false
        ? props.theme.spacing.M16
        : props.theme.spacing.L40};
  width: 100%;
`;

export const StyledA = styled.a`
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  color: ${({ theme }) => theme.color.PRIMARY};
`;

export const ButtonContainer = styled.div<{
  narrow: boolean;
  spacer: boolean;
}>`
  display: flex;
  justify-content: space-between;
  ${({ spacer, theme }) =>
    spacer &&
    css`
      // Aligns buttons with inputs with labels in horizontal forms
      @media screen and (min-width: 900px) {
        padding-top: ${theme.spacing.M24};
      }
    `}
  ${({ narrow, theme }) =>
    narrow === true &&
    ` 
      
      margin: 0 auto;
      padding-top: ${theme.spacing.M24};
      padding-bottom: ${theme.spacing.L40};
      padding-left: ${theme.spacing.M24};
      padding-right: ${theme.spacing.M24};
      @media only screen and (min-width: 1004px) { 
        padding: ${theme.spacing.M24} 0 ${theme.spacing.L64};
        max-width: 480px;
      }
  `};
`;

export const Label = styled.label`
  ${({ theme }) => theme.fontSize.S14};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  margin-bottom: ${({ theme }) => theme.spacing.S8};
  display: block;
`;

export const Info = styled.p`
  ${({ theme }) => theme.fontSize.S14};
  color: ${({ theme }) => theme.color.GREY_DARKER};
  margin-bottom: ${({ theme }) => theme.spacing.L40};
`;
export const H5 = styled.h5`
  ${({ theme }) => theme.fontSize.S14};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  color: ${({ theme }) => theme.color.GREY_DARKER};
  margin-bottom: ${({ theme }) => theme.spacing.S4};
  @media only screen and (min-width: ${PAGE_BREAK_POINT_800PX}) {
    ${({ theme }) => theme.fontSize.S16};
  }
`;

export const Body = styled.p`
  ${({ theme }) => theme.fontSize.S14};
  color: ${({ theme }) => theme.color.GREY_DARKER};
  margin-bottom: ${({ theme }) => theme.spacing.S12};
  @media only screen and (min-width: ${PAGE_BREAK_POINT_800PX}) {
    ${({ theme }) => theme.fontSize.S16};
  }
`;
