import React from 'react';

import { Dropdown } from '@dsch/form-kit';
import styled from 'styled-components';

const CustomSelect = styled(({ hasError, ...rest }) => <Dropdown {...rest} />)`
  display: inline-flex;
  padding: 2px;
  overflow: hidden;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iQXJ0Ym9hcmQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+ICAgICAgICA8cGF0aCBkPSJNMTYsMTAgTDEyLjE4ODQ0NDQsMTMuOTE5NTk4NiBDMTIuMDg0NjIyMiwxNC4wMjY3Mzk0IDExLjkxNTczMzMsMTQuMDI2NzM5NCAxMS44MTE5MTExLDEzLjkxOTk2NDMgQzExLjgxMTkxMTEsMTMuOTE5OTY0MyAxMS44MTE1NTU2LDEzLjkxOTU5ODYgMTEuODExNTU1NiwxMy45MTk1OTg2IEw4LDEwIiBpZD0iRFJPUERPV04tQlVUVE9OIiBzdHJva2U9IiM5OTk5OTkiIHN0cm9rZS13aWR0aD0iMS4yIj48L3BhdGg+ICAgIDwvZz48L3N2Zz4=)
    no-repeat right 4px center;

  appearance: none;
  width: 100%;
  background-color: transparent;
  border: 1px solid #bbbbbb;
  height: 48px;
  padding-left: 20px;
  padding-right: 28px;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
  border-radius: 4px;
  text-align: left;
  color: #333333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    border: 1px solid #bbbbbb;
  }

  &.filterIsActive:not(:focus) {
    border: 2px solid black;
  }

  @media only screen and (min-width: 704px) {
    font-size: 14px;
  }

  @media print {
    font-size: 12px;
  }
  :disabled {
    background-color: #f4f4f4;
    border: 1px solid #dddddd;
    color: #bbbbbb;
  }
  /* IE11 Hack */
  ::-ms-expand {
    display: none;
  }

  ${(props: { hasError?: boolean }) =>
    props.hasError &&
    `
    border: 2px solid #d50000;
    `};
`;

export const DaftDropdown = ({ multiple, ...propsToBePassed }: any) => {
  if (multiple) {
    return <div>This component is not supported as a multiple select</div>;
  }
  return <CustomSelect {...propsToBePassed} />;
};
