import React from 'react';

import { CheckboxProps } from '@dsch/form-kit';
import styled from 'styled-components';

export type AdditionalProps = CheckboxProps & {
  hasError?: boolean;
  checkboxText?: string;
};

type StyledProps = {
  hasError?: boolean;
  disabled?: boolean;
  checked?: boolean;
};
const StyledCheckboxText = styled.span<StyledProps>`
  z-index: 10;

  &:before {
    display: inline-block;
    content: '';
    background-size: contain;
    vertical-align: middle;
    margin: 0 10px 0 0;
    height: 20px;
    width: 20px;
    ${(props) =>
      !props.checked &&
      `
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAG1BMVEXd3d3e3t7d3d3d3d3d3d3d3d3d3d3d3d3///9sfAbiAAAAB3RSTlNKkdzg4eTxodeYHAAAAAFiS0dECIbelXoAAAAfSURBVBjTYxAvhwMFhnQEp5ihvAMOykcsBwFKkDkBAMC/kjStMTFbAAAAAElFTkSuQmCC');
      `};

    ${(props) =>
      props.checked &&
      `
background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyNHB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CjwhLS1HZW5lcmF0b3I6IFNrZXRjaCA1NS4yICg3ODE4MSkgLSBodHRwczovL3NrZXRjaGFwcC5jb20tLT4KICAgIDx0aXRsZT4KICAgICAgICBTZWxlY3RlZCBFbmFibGVkCiAgICA8L3RpdGxlPgogICAgPGRlc2M+CiAgICAgICAgQ3JlYXRlZCB3aXRoIFNrZXRjaC4KICAgIDwvZGVzYz4KICAgIDxnIGlkPSJTZWxlY3RlZC1FbmFibGVkIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj4KICAgICAgICA8cGF0aCBpZD0iU2hhcGUiIGZpbGw9IiMzMzMzMzMiIGQ9Ik0yMS4zMzMzMzMzLDAgTDIuNjY2NjY2NjcsMCBDMS4yLDAgMCwxLjIgMCwyLjY2NjY2NjY3IEwwLDIxLjMzMzMzMzMgQzAsMjIuOCAxLjIsMjQgMi42NjY2NjY2NywyNCBMMjEuMzMzMzMzMywyNCBDMjIuOCwyNCAyNCwyMi44IDI0LDIxLjMzMzMzMzMgTDI0LDIuNjY2NjY2NjcgQzI0LDEuMiAyMi44LDAgMjEuMzMzMzMzMywwIEwyMS4zMzMzMzMzLDAgWiBNOS4zMzMzMzMzMywxOC42NjY2NjY3IEwyLjY2NjY2NjY3LDEyLjI1NjQxMDMgTDQuNTMzMzMzMzMsMTAuNDYxNTM4NSBMOS4zMzMzMzMzMywxNS4wNzY5MjMxIEwxOS40NjY2NjY3LDUuMzMzMzMzMzMgTDIxLjMzMzMzMzMsNy4xMjgyMDUxMyBMOS4zMzMzMzMzMywxOC42NjY2NjY3IEw5LjMzMzMzMzMzLDE4LjY2NjY2NjcgWiIvPgogICAgPC9nPgo8L3N2Zz4K');
      `};
    ${(props) =>
      props.disabled &&
      !props.checked &&
      `
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAATElEQVRIie3NQQ2AMBAAwSkCsIA+PvWCwGsCCQJQUCzw4H63AnbaGOOYc3b/F+gtIh6sCQCcS+IctiVxDgoooIACCiiggC9dmcCN/QVDwg7kpP3TkwAAAABJRU5ErkJggg==');
      `};
    ${(props) =>
      props.disabled &&
      props.checked &&
      `
        background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAA0lBMVEUAAAD////b29vc3Nzf39/i4uLf39/b29vd3d3g4ODb29vc3Nzd3d3e3t7b29vf39/b29vd3d3e3t7c3Nzc3Nzc3Nzd3d3b29vc3Nzd3d3e3t7c3Nzd3d3e3t7d3d3c3Nzc3Nze3t7d3d3e3t7c3Nzd3d3d3d3d3d3d3d3d3d3e3t7d3d3d3d3d3d3d3d3c3Nzd3d3d3d3d3d3d3d3e3t7d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d0J3vUlAAAARXRSTlMAAhUWGBogIyUpKiwtLzE3OTw9QkhJSk9XW11fYWVwfYuSpqipqqytyNTd4OHi5OXm6Orr7O7v8PHy8/T19vf4+vv8/f5YgvlyAAAAAWJLR0QB/wIt3gAAAKtJREFUKM+dktUSwjAURA8Ud3d3dytOS///lxh0gKQPcN+yZybZ3RuU6twQZl6x0jCkU2ctByqGyfwC9qWTFKzCxHcSoEbAOxHBNAChpXjV0A3Rzevxpv7Qxz6IqE9Xep7U8XboOCB5eNkd2CF9ddh1QmL/lqN3F9o2yGgfAUceiLUUyGlfyfsusEBWEyqZ+IHCWdLVIkhRXuKs/H/tD2C6WtPPoNR2orytWi8r3oc713xFbwAAAABJRU5ErkJggg==');
      `};
  }
`;

const StyledCheckboxLabel = styled.button<StyledProps>`
  touch-action: manipulation;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  padding: 0;
  background-color: transparent;
  text-align: left;

  ${StyledCheckboxText} {
    &:before {
      ${(props) =>
        props.disabled &&
        !props.checked &&
        `
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAATElEQVRIie3NQQ2AMBAAwSkCsIA+PvWCwGsCCQJQUCzw4H63AnbaGOOYc3b/F+gtIh6sCQCcS+IctiVxDgoooIACCiiggC9dmcCN/QVDwg7kpP3TkwAAAABJRU5ErkJggg==');
      `};
      ${(props) =>
        props.disabled &&
        props.checked &&
        `
        background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAA0lBMVEUAAAD////b29vc3Nzf39/i4uLf39/b29vd3d3g4ODb29vc3Nzd3d3e3t7b29vf39/b29vd3d3e3t7c3Nzc3Nzc3Nzd3d3b29vc3Nzd3d3e3t7c3Nzd3d3e3t7d3d3c3Nzc3Nze3t7d3d3e3t7c3Nzd3d3d3d3d3d3d3d3d3d3e3t7d3d3d3d3d3d3d3d3c3Nzd3d3d3d3d3d3d3d3e3t7d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d3d0J3vUlAAAARXRSTlMAAhUWGBogIyUpKiwtLzE3OTw9QkhJSk9XW11fYWVwfYuSpqipqqytyNTd4OHi5OXm6Orr7O7v8PHy8/T19vf4+vv8/f5YgvlyAAAAAWJLR0QB/wIt3gAAAKtJREFUKM+dktUSwjAURA8Ud3d3dytOS///lxh0gKQPcN+yZybZ3RuU6twQZl6x0jCkU2ctByqGyfwC9qWTFKzCxHcSoEbAOxHBNAChpXjV0A3Rzevxpv7Qxz6IqE9Xep7U8XboOCB5eNkd2CF9ddh1QmL/lqN3F9o2yGgfAUceiLUUyGlfyfsusEBWEyqZ+IHCWdLVIkhRXuKs/H/tD2C6WtPPoNR2orytWi8r3oc713xFbwAAAABJRU5ErkJggg==');
      `};

      ${(props) =>
        props.hasError &&
        `
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAG1BMVEXPAxzQAhrPAhvQAhvRAhvQAhvQAhzQAhv///+gf7czAAAAB3RSTlNKkdzg4eTxodeYHAAAAAFiS0dECIbelXoAAAAfSURBVBjTYxAvhwMFhnQEp5ihvAMOykcsBwFKkDkBAMC/kjStMTFbAAAAAElFTkSuQmCC');
      `};
    }
  }
`;

export const FauxCheckbox = ({
  disabled,
  checked,
  hasError,
  checkboxText,
}: AdditionalProps) => (
  <StyledCheckboxLabel disabled={disabled} hasError={hasError}>
    <StyledCheckboxText disabled={disabled} checked={checked}>
      {checkboxText}
    </StyledCheckboxText>
  </StyledCheckboxLabel>
);
