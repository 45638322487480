import React, { useEffect, useState, useMemo } from 'react';

import styled from 'styled-components';

import {
  TextInputProps,
  ListSingleSelectProps,
} from '../../../services/Filters/FilterTypes';

import { TermsFilter } from '../../TermsFilter/TermsFilter';
import VariableSingleSelect from '../SingleSelect/VariableSingleSelect';

interface currentValueType {
  [index: string]: string;
}

export interface TextInputWithDrodownProps {
  name: string;
  termsFilterProps: TextInputProps;
  singleSelectProps: ListSingleSelectProps;
  onChange: (currentValue: currentValueType) => void;
  onBlur?: () => void;
  value: currentValueType;
  disabled?: boolean;
  dataTestId?: string;
}

const StyledDiv = styled.div`
  display: flex;
`;

const TermsFilterWrapper = styled.div`
  width: calc(58% - 8px);
  margin-right: 8px;
`;

const VariableSingleSelectWrapper = styled.div`
  width: 42%;
`;

const TextInputWithDropdown = (props: TextInputWithDrodownProps) => {
  const {
    name,
    termsFilterProps,
    singleSelectProps,
    onChange,
    value,
    disabled,
    dataTestId,
  } = props;

  const [currentValue, setCurrentValue] = useState(
    value || {
      [termsFilterProps.name]: termsFilterProps.currentValue || '',
      [singleSelectProps.name]: singleSelectProps.currentValue.values[0],
    },
  );

  const disabledValue = useMemo(
    () => ({
      [termsFilterProps.name]: '',
      [singleSelectProps.name]: singleSelectProps.currentValue.values[0],
    }),
    [],
  );

  useEffect(() => {
    disabled && setCurrentValue(disabledValue);
  }, [disabled, disabledValue]);

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue, onChange]);

  const handleChange = (event: any) => {
    setCurrentValue({
      ...currentValue,
      [event.name]:
        event.filterType === 'TextInput'
          ? event.currentValue
          : event.currentValue.values[0],
    });
  };

  return (
    <StyledDiv data-testid={dataTestId ? dataTestId : name}>
      <TermsFilterWrapper>
        <TermsFilter
          {...termsFilterProps}
          onChange={handleChange}
          searchQueryGroup="terms"
          onBlur={props.onBlur}
          currentValue={value[termsFilterProps.name]}
          fakeDisabled={disabled}
        />
      </TermsFilterWrapper>
      <VariableSingleSelectWrapper>
        <VariableSingleSelect
          {...singleSelectProps}
          onChange={handleChange}
          dataTestId={name}
          showLabel={false}
          disabled={disabled}
          currentValue={{ values: [value[singleSelectProps.name]] }}
        />
      </VariableSingleSelectWrapper>
    </StyledDiv>
  );
};

export default TextInputWithDropdown;
