import React from 'react';

import { RadioButtonGroup } from '@dsch/form-kit';
import { RadioButtonProps as UnityRadioButtonProps } from '@dsch/form-kit';
import styled from 'styled-components';

import {
  RadioButton,
  RadioButtonProps,
} from '../../Molecules/RadioButton/RadioButton';

type RadioButtonInputType = Omit<
  RadioButtonProps,
  'name' | 'titlePrimaryLabel' | 'onChange'
>;

type RadioButtonValue = RadioButtonInputType & {
  displayName: string;
  value: string;
};
export type AdditionalProps = UnityRadioButtonProps & {
  disabled?: boolean;
  values: RadioButtonValue[];
  value?: string;
  name: string;
  onChange: any;
  id?: string;
  displayName?: string;
  showLabel?: boolean;
  description?: string;
  hasError?: boolean;
};

export const TextLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
  color: #333333;
`;

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  width: 100%;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 12px;
`;

const DescriptionWrapper = styled.p`
  ${({ theme }) => theme.fontSize.B12};
  margin-top: ${({ theme }) => theme.spacing.M16};
  color: ${({ theme }) => theme.color.GREY_DARK};
`;

export const RadioButtonGroupInput = ({
  displayName,
  values,
  showLabel = true,
  hasError = false,
  description,
  ...props
}: AdditionalProps) => (
  <>
    {showLabel && <TextLabel>{displayName}</TextLabel>}
    <StyledRadioButtonGroup
      radioButtons={({
        currentValue,
        name,
        handleChange,
        disabled,
      }: {
        currentValue: string;
        name: string;
        handleChange: any;
        disabled: boolean;
      }) => (
        <>
          <input
            data-testid={`input-hidden-${name}`}
            type="hidden"
            value={currentValue}
            name={name}
          />
          {values.map((radioButtonValue: RadioButtonValue, index: number) => (
            <RadioButton
              {...radioButtonValue}
              id={`${name}-item-${index}`}
              dataTestId={`${name}-item-${index}`}
              key={`${name}-item-${index}`}
              value={radioButtonValue.value}
              titlePrimaryLabel={radioButtonValue.displayName}
              checked={radioButtonValue.value === currentValue}
              onChange={handleChange}
              disabled={disabled}
              hasError={hasError}
              name={name}
              buttonType={radioButtonValue.buttonType}
            />
          ))}
        </>
      )}
      {...props}
    />
    {description && <DescriptionWrapper>{description}</DescriptionWrapper>}
  </>
);
