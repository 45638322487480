import { mockEndpoints } from '../../config';

const isServer = () => typeof window === 'undefined';

export const getInternalEndpoint = (
  endpointKey: string,
  ENVIRONMENT_NAME = 'production',
  isService = false,
) => {
  const envNameMap: { [key: string]: 'prod' | 'staging' } = {
    production: 'prod',
    staging: 'staging',
  };

  if (isService && ENVIRONMENT_NAME === 'production') {
    return `http://dsch-${process.env[endpointKey]}.shared-platform.svc:8080`;
  }
  return `http://${process.env[endpointKey]}.${envNameMap[ENVIRONMENT_NAME]}.dsch.internal`;
};

export const getExternalEndpoint = ({
  endpointKey,
  ext = 'ie',
}: {
  endpointKey: string;
  ext: string;
}) => `https://${process.env[endpointKey]}.dsch.${ext}`;

export const getGatewayEndpointBasedOnEnvironment = ({
  endpointKey,
  ENVIRONMENT_NAME,
  isServer,
  INTEGRATION_TESTING = false,
  isService,
}: {
  endpointKey: string;
  ENVIRONMENT_NAME: string;
  isServer: boolean;
  INTEGRATION_TESTING?: boolean;
  isService?: boolean;
}) => {
  // when running integration tests return mountebank mock endpoint if set
  if (INTEGRATION_TESTING && mockEndpoints[endpointKey]) {
    return mockEndpoints[endpointKey];
  }

  if (isService && !isServer && ENVIRONMENT_NAME !== 'dev') {
    return '';
  }

  if (isServer && ['production', 'staging'].includes(ENVIRONMENT_NAME)) {
    return getInternalEndpoint(endpointKey, ENVIRONMENT_NAME, isService);
  } else {
    return getExternalEndpoint({
      endpointKey,
      ext: ENVIRONMENT_NAME === 'production' ? 'ie' : 'dev',
    });
  }
};

// Use internal endpoints on production server side calls
export const getServiceEndpoint = ({
  endpointKey,
  isService,
}: {
  endpointKey: string;
  isService?: boolean;
}) => {
  const INTEGRATION_TESTING = Boolean(
    process.env.NEXT_PUBLIC_INTEGRATION_TESTING,
  );
  const ENVIRONMENT_NAME = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME;
  return getGatewayEndpointBasedOnEnvironment({
    endpointKey,
    ENVIRONMENT_NAME,
    isServer: isServer(),
    INTEGRATION_TESTING,
    isService,
  });
};
