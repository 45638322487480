import React from 'react';

import styled from 'styled-components';

import { ColorTypes, FontWeightTypes, ThemeTypes } from '../../../types/theme';

import { InputLabelTypes } from './InputLabel.types';

export type InputLabelProps = {
  inputPrimaryLabel: string;
  inputSecondaryLabel?: string;
  inputPrimaryLabelColor?: keyof ColorTypes;
  inputSecondaryLabelColor?: keyof ColorTypes;
  labelSize: InputLabelTypes;
  hasLineBreak?: boolean;
  fontWeight: keyof FontWeightTypes;
  testId: string;
  isDisabled: boolean;
};

const getLabelSize = (labelSize: InputLabelTypes, theme: ThemeTypes) => {
  switch (labelSize) {
    case InputLabelTypes.B12:
      return `${theme.fontSize.B12}`;
    case InputLabelTypes.S14:
      return `${theme.fontSize.S14} `;
    case InputLabelTypes.S16:
      return `${theme.fontSize.S16}`;
    case InputLabelTypes.T18:
      return `${theme.fontSize.T18}`;
    case InputLabelTypes.T24:
      return `${theme.fontSize.T24}`;
    default:
      return `${theme.fontSize.S16}`;
  }
};

const StyledInputLabel = styled.div<{
  labelSize: InputLabelTypes;
  fontWeight: keyof FontWeightTypes;
  hasLineBreak?: boolean;
  inputPrimaryLabelColor: keyof ColorTypes;
  inputSecondaryLabelColor: keyof ColorTypes;
  isDisabled: boolean;
  hasSecondaryLabel: boolean;
}>`
  display: inline-flex;
  align-items: flex-start;
  text-align: start;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;

  ${({ labelSize, theme }) => getLabelSize(labelSize, theme)};
  ${({ hasLineBreak }) =>
    hasLineBreak ? 'flex-direction: column;' : 'flex-direction: row;'};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? theme.fontWeight[fontWeight] : theme.fontWeight.REGULAR};

  span:first-child {
    width: ${({ hasSecondaryLabel }) => hasSecondaryLabel && 'max-content'};
    color: ${({ theme, inputPrimaryLabelColor, isDisabled }) =>
      isDisabled ? theme.color.GREY : theme.color[inputPrimaryLabelColor]};
    padding-right: ${({ labelSize, theme, hasSecondaryLabel }) =>
      hasSecondaryLabel
        ? labelSize === InputLabelTypes.B12
          ? theme.spacing.S4
          : theme.spacing.S8
        : ''};
  }

  span:last-child {
    color: ${({ theme, inputSecondaryLabelColor, isDisabled }) =>
      isDisabled ? theme.color.GREY : theme.color[inputSecondaryLabelColor]};
    font-weight: ${({ theme }) => theme.fontWeight.REGULAR};
    word-break: keep-all;
  }
`;

export const InputLabel = (props: InputLabelProps) => {
  const {
    hasLineBreak = false,
    fontWeight = 'REGULAR',
    inputPrimaryLabel,
    inputSecondaryLabel,
    inputPrimaryLabelColor = 'GREY_DARKER',
    inputSecondaryLabelColor = 'GREY_DARK',
    isDisabled = false,
    labelSize = InputLabelTypes.S16,
    testId,
  } = props;

  return (
    <StyledInputLabel
      labelSize={labelSize}
      fontWeight={fontWeight}
      inputPrimaryLabelColor={inputPrimaryLabelColor}
      inputSecondaryLabelColor={inputSecondaryLabelColor}
      data-testid={`input-label-${testId}`}
      hasLineBreak={hasLineBreak}
      isDisabled={isDisabled}
      hasSecondaryLabel={inputSecondaryLabel ? true : false}
    >
      <span data-testid={`primary-label-${testId}`}>{inputPrimaryLabel}</span>
      <span data-testid={`secondary-label-${testId}`}>
        {inputSecondaryLabel}
      </span>
    </StyledInputLabel>
  );
};
