import React from 'react';

import { Field } from 'react-final-form';

import { GroupedStepper } from '../../../../../components/Filters/GroupedStepper/GroupedStepper';
import { GroupedStepperProps } from '../../../../../services/Filters/FilterTypes';

import { CustomErrorMessage, CustomFormField } from './BedroomStepper.styled';
import { bedroomValues } from '../../PlaceAdForm.config';

const CustomHandleError = ({ meta, name }: { meta: any; name: string }) => {
  if (
    meta?.touched &&
    !meta.dirtySinceLastSubmit &&
    (meta.error || meta.submitError)
  ) {
    return (
      <CustomErrorMessage data-testid={`${name}-validation-error`}>
        {meta.error || meta.submitError}
      </CustomErrorMessage>
    );
  }
  return null;
};

const BedroomStepper = ({ initialValues }: any) => {
  const { type } = initialValues;
  const bedrooms = bedroomValues({
    type,
  });
  const { totalBeds, ...rest } = initialValues.bedrooms || {};
  const currentBedroom =
    initialValues && initialValues.bedrooms !== undefined
      ? type === 'sale'
        ? initialValues.bedrooms
        : { ...rest }
      : type === 'sale'
        ? {
            totalBeds: bedrooms.elements[0].currentValue,
          }
        : {
            doubleBeds: bedrooms.elements[0].currentValue,
            singleBeds: bedrooms.elements[1].currentValue,
            twinBeds: bedrooms.elements[2].currentValue,
          };

  const RentalBedroomValidation = (value: any) => {
    const response =
      value &&
      (('singleBeds' in value && value['singleBeds'] > 0) ||
        ('doubleBeds' in value && value['doubleBeds'] > 0) ||
        ('twinBeds' in value && value['twinBeds'] > 0));
    return response ? undefined : 'Please enter number of bedrooms';
  };

  const SaleBedroomValidation = (value: any) => {
    const response = value && value.totalBeds > 0;
    return response ? undefined : 'Please enter number of bedrooms';
  };

  return (
    <Field
      type="text"
      name={bedrooms.name}
      key={bedrooms.name}
      validate={
        type === 'sale' ? SaleBedroomValidation : RentalBedroomValidation
      }
      subscription={{
        value: true,
        active: true,
        error: true,
        dirtySinceLastSubmit: true,
        submitError: true,
        touched: true,
      }}
    >
      {({ input, meta }) => {
        return (
          <CustomFormField data-testid={`input-field-bedrooms`}>
            <CustomHandleError meta={meta} name={input.name} />
            <GroupedStepper
              {...(bedroomValues({
                type,
              }) as unknown as GroupedStepperProps)}
              currentValue={currentBedroom}
              onChange={input.onChange}
              hasError={
                meta?.touched &&
                !meta.dirtySinceLastSubmit &&
                (meta.error || meta.submitError)
              }
            />
          </CustomFormField>
        );
      }}
    </Field>
  );
};

export default BedroomStepper;
