import { SuccessIcon } from '@dsch/icons';
import styled from 'styled-components';

import { DelayedSpinner } from '../../Toolkit/Spinner/DelayedSpinner';

export const PAGE_MOBILE_BREAK_POINT = '640px';
export const PAGE_BREAK_POINT_800 = '800px';
export const PAGE_BREAK_POINT = '1004px';
export const PAGE_BREAK_POINT_1080PX = '1080px';

export const StyledSuccessIcon = styled(SuccessIcon)`
  color: ${({ theme }) => theme.color.SUCCESS};
`;

export type FontSize = {
  mobile?: string;
  desktop?: string;
};

export const StyledIconText = styled.div<{ fontSize?: FontSize }>`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: ${({ fontSize }) =>
    fontSize?.mobile ? fontSize?.mobile : '14px'};
  @media only screen and (min-width: 640px) {
    font-size: ${({ fontSize }) =>
      fontSize?.mobile ? fontSize?.mobile : '16px'};
  }
`;

export const CenteredSpinner = styled(DelayedSpinner)`
  position: absolute;
  top: calc(50% - 20px);
  right: calc(50% - 20px);
  width: 40px;
  height: 40px;
`;

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.L48} 0;

  @media only screen and (min-width: ${PAGE_MOBILE_BREAK_POINT}) {
    padding-top: ${({ theme }) => theme.spacing.L64};
  }
`;

export const MainFlexWrapper = styled.div`
  margin: 0;
  padding-bottom: ${({ theme }) => theme.spacing.M32};
  flex-direction: column;

  @media only screen and (min-width: ${PAGE_BREAK_POINT}) {
    padding: 0 20px 106px;
    display: flex;
  }
  @media only screen and (min-width: ${PAGE_BREAK_POINT_1080PX}) {
    padding: 0 0 106px;
  }
`;

export const H1 = styled.h1`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  ${({ theme }) => theme.fontSize.S16};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  margin-bottom: ${({ theme }) => theme.spacing.M32};

  @media only screen and (min-width: ${PAGE_MOBILE_BREAK_POINT}) {
    ${({ theme }) => theme.fontSize.T24};
    margin-bottom: ${({ theme }) => theme.spacing.L40};
  }
`;

export const H3 = styled.h3`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  ${({ theme }) => theme.fontSize.S16};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  margin-bottom: ${({ theme }) => theme.spacing.M32};

  @media only screen and (min-width: ${PAGE_MOBILE_BREAK_POINT}) {
    ${({ theme }) => theme.fontSize.T18};
    margin-bottom: ${({ theme }) => theme.spacing.L40};
  }
`;

export const WidthWrapper = styled.div`
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.M16};

  @media only screen and (min-width: ${PAGE_MOBILE_BREAK_POINT}) {
    padding: 0;
  }
`;

export const PanelWrapper = styled(WidthWrapper)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.L48};
`;

export const Card = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.WHITE};
  border-radius: ${({ theme }) => theme.border.radius.STANDARD};

  @media only screen and (min-width: ${PAGE_MOBILE_BREAK_POINT}) {
    margin: 0 auto;
    width: 100%;
  }
`;

export const CardRow = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.M20};

  @media only screen and (min-width: ${PAGE_MOBILE_BREAK_POINT}) {
    padding: 0 ${({ theme }) => theme.spacing.L48};
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.M32} 0
    ${({ theme }) => theme.spacing.S4} 0;

  @media only screen and (min-width: ${PAGE_MOBILE_BREAK_POINT}) {
    margin: ${({ theme }) => theme.spacing.M24} 0;
    flex-direction: row;
  }
`;

export const ResponsiveFlexRow = styled(FlexRow)`
  flex-direction: column;
  flex-wrap: wrap;

  @media only screen and (min-width: ${PAGE_MOBILE_BREAK_POINT}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const LinkFlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (min-width: ${PAGE_MOBILE_BREAK_POINT}) {
    justify-content: flex-end;
    flex-direction: column;
    margin-left: auto;
    margin-right: 10%;
  }
`;

export const BudgetNoResultLabel = styled.div`
  width: 80%;
  flex: 1;
  ${({ theme }) => theme.fontSize.S16};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.M20};
  @media only screen and (min-width: ${PAGE_MOBILE_BREAK_POINT}) {
    width: 100%;
    ${({ theme }) => theme.fontSize.T18};
    margin-bottom: ${({ theme }) => theme.spacing.M20};
  }
`;

export const CTALink = styled.a`
  margin-bottom: ${({ theme }) => theme.spacing.L48};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  ${({ theme }) => theme.fontSize.S14};
  text-decoration-line: underline;
  color: ${({ theme }) => theme.color.PRIMARY};
  @media only screen and (min-width: ${PAGE_MOBILE_BREAK_POINT}) {
    ${({ theme }) => theme.fontSize.S16};
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;
