import React, { useState, useEffect } from 'react';

import { Field } from 'react-final-form';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';

import VariableSingleSelect from '../../../../../components/Filters/SingleSelect/VariableSingleSelect';
import { Stepper } from '../../../../../components/Filters/Stepper/Stepper';
import { Label } from '../../../../../components/Forms/common/Label';
import { HandleError } from '../../../../../components/Forms/FormBuilder';
import { setCurrencyNiOption } from '../../../../../components/Forms/PlaceAdForm/PlaceAd.helpers';
import * as S from '../../../../../components/Forms/Styles';
import { TermsFilter } from '../../../../../components/TermsFilter/TermsFilter';
import { Checkbox } from '../../../../../components/Toolkit/Checkbox/Checkbox';
import { RadioButtonGroupInput } from '../../../../../components/Toolkit/RadioButtonGroupInput/RadioButtonGroupInput';

import { Divider, BedroomTitle, CustomFormField } from './SharingPrice.styled';

const validation = (value: string) => {
  return value ? undefined : 'This field is required';
};

const typeValidation = (value: { values: [string] }) => {
  return value.values[0] ? undefined : 'This field is required';
};

const priceValidation = (value: { currentValue: any }) => {
  const response = value?.currentValue;

  const matchesRegex = new RegExp('(^[1-9]\\d*$)|(^(0)+[1-9]+$)').test(
    response,
  );

  if (!matchesRegex)
    return 'Please enter a weekly or monthly amount, using numbers only.';

  return response ? undefined : 'This field is required';
};

export const SharingPrice = ({ initialValues }: any) => {
  const { collectionPeriod, currency, bedrooms } = initialValues?.price || {};

  const [period, setPeriod] = useState(collectionPeriod);
  const { mutators } = useForm();

  const showBedroomDetail = (value: any, previous: any) => {
    if (value > previous) mutators.push('bedrooms', undefined);
    else mutators.pop('bedrooms');
  };

  const changePeriod = (value: any) => {
    setPeriod(value);
  };

  useEffect(() => {
    bedrooms?.length > 1
      ? bedrooms.map(() => mutators.push('bedrooms', undefined))
      : mutators.push('bedrooms', undefined);
  }, []);

  return (
    <>
      <Field
        type="text"
        name={'collectionPeriod'}
        key={'collectionPeriod'}
        validate={validation}
        initialValue={collectionPeriod || ''}
        subscription={{
          value: true,
          active: true,
          error: true,
          dirtySinceLastSubmit: true,
          submitError: true,
          touched: true,
        }}
      >
        {({ input, meta }) => {
          return (
            <S.FormField data-testid={`input-field-collectionLabel`}>
              <Label
                htmlFor={''}
                data-testid={`collection-label`}
                displayName={'Rent collection'}
                optional={false}
              />
              <RadioButtonGroupInput
                value={input.value}
                name={input.name}
                values={[
                  { displayName: 'Monthly', value: 'monthly' },
                  { displayName: 'Weekly', value: 'weekly' },
                ]}
                onChange={input.onChange}
              />
              <HandleError meta={meta} name={input.name} />
              <OnChange name="collectionPeriod">
                {() => {
                  changePeriod(input.value);
                }}
              </OnChange>
            </S.FormField>
          );
        }}
      </Field>
      <Field
        type="number"
        name={'bedroomAvailable'}
        key={'bedroomAvailable'}
        validate={() => {}}
        initialValue={bedrooms?.length || 1}
        subscription={{
          value: true,
          active: true,
          error: true,
          dirtySinceLastSubmit: true,
          submitError: true,
          touched: true,
        }}
      >
        {({ input, meta }) => {
          return (
            <CustomFormField
              spacing={'wild'}
              data-testid={'input-field-numBedrooms'}
            >
              <Stepper
                id={'numBedrooms'}
                name={'numBedrooms'}
                displayName={'Bedrooms available'}
                variant={'DAFT_DEFAULT'}
                filterType={{
                  id: '10',
                  name: 'Stepper',
                }}
                required={false}
                stepSize={1}
                min={1}
                max={4}
                hasError={false}
                currentValue={input.value}
                onChange={input.onChange}
              />
              <HandleError meta={meta} name={input.name} />
              <OnChange name="bedroomAvailable">
                {(value, previous) => {
                  showBedroomDetail(value, previous);
                }}
              </OnChange>
            </CustomFormField>
          );
        }}
      </Field>
      <Field
        type="text"
        name={'currency'}
        key={'currency'}
        validate={() => {}}
        initialValue={
          currency && setCurrencyNiOption(initialValues) == true ? 'GBP' : 'EUR'
        }
      >
        {({ input }) => {
          return (
            <input
              type="hidden"
              name={input.name}
              value={input.value}
              onChange={input.onChange}
            />
          );
        }}
      </Field>
      <FieldArray name="bedrooms">
        {({ fields }) =>
          fields.map((name, index) => (
            <>
              <Divider />
              <Field
                type="text"
                name={`rentalValue-${index}`}
                key={`rentalValue-${index}`}
                validate={priceValidation}
                initialValue={{
                  currentValue: bedrooms && bedrooms[index]?.rentalValue,
                }}
                subscription={{
                  value: true,
                  active: true,
                  error: true,
                  dirtySinceLastSubmit: true,
                  submitError: true,
                  touched: true,
                }}
              >
                {({ input, meta }) => {
                  return (
                    <S.FormField data-testid={`input-field-${name}`}>
                      <BedroomTitle
                        data-testid={`bedroom-title-${index}`}
                      >{`Bedroom ${index + 1}`}</BedroomTitle>
                      <TermsFilter
                        id={index}
                        name={'rent'}
                        displayName={'Rent'}
                        searchQueryGroup={'terms'}
                        showLabel={true}
                        options={{
                          shouldShowButton: false,
                          prefix:
                            setCurrencyNiOption(initialValues) == true
                              ? '£'
                              : '€',
                          suffix:
                            period &&
                            period[0].toUpperCase() + period.substring(1),
                        }}
                        currentValue={input.value.currentValue || ''}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        inputType={'currency'}
                        fakeDisabled={false}
                      />
                      <HandleError meta={meta} name={input.name} />
                      <OnChange name={`rentalValue-${index}`}>
                        {() => {}}
                      </OnChange>
                    </S.FormField>
                  );
                }}
              </Field>
              <Field
                type="select"
                name={`type-${index}`}
                key={`type-${index}`}
                initialValue={{
                  values: [bedrooms && bedrooms[index]?.type],
                }}
                validate={typeValidation}
                subscription={{
                  value: true,
                  active: true,
                  error: true,
                  dirtySinceLastSubmit: true,
                  submitError: true,
                  touched: true,
                }}
              >
                {({ input, meta }) => {
                  return (
                    <CustomFormField
                      spacing={'narrow'}
                      data-testid={`input-field-${name}`}
                    >
                      <Label
                        htmlFor={'Room type'}
                        displayName={'Room type'}
                        optional={false}
                      />
                      <VariableSingleSelect
                        filterType={{
                          name: 'ListSingleSelect',
                        }}
                        displayName={'Select option'}
                        values={[
                          {
                            displayName: 'Select bedroom type',
                            value: '',
                          },
                          {
                            displayName: 'Single room',
                            value: 'single',
                          },
                          {
                            displayName: 'Double room',
                            value: 'double',
                          },
                          {
                            displayName: 'Twin room',
                            value: 'twin',
                          },
                          {
                            displayName: 'Shared room',
                            value: 'shared',
                          },
                        ]}
                        currentValue={input.value}
                        showLabel={false}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        disabled={false}
                      />
                      <HandleError meta={meta} name={input.name} />
                      <OnChange name={`type-${index}`}>{() => {}}</OnChange>
                    </CustomFormField>
                  );
                }}
              </Field>
              <Field
                type="checkbox"
                name={`ensuite-${index}`}
                key={`checkbox-${index}`}
                initialValue={bedrooms && bedrooms[index]?.ensuite}
                subscription={{
                  value: true,
                  active: true,
                  error: true,
                  dirtySinceLastSubmit: true,
                  touched: true,
                  submitError: true,
                }}
              >
                {({ input, meta }) => {
                  return (
                    <CustomFormField
                      spacing={'wild'}
                      data-testid={`input-field-${name}`}
                    >
                      <S.CheckboxWrapper>
                        <Checkbox
                          name={input.name}
                          id={input.name}
                          onChange={input.onChange}
                          checked={input.checked}
                          checkboxText={'En-suite'}
                          isOptional={false}
                          hasError={
                            meta?.touched &&
                            !meta.dirtySinceLastSubmit &&
                            Boolean(meta.error || meta.submitError)
                          }
                        />
                      </S.CheckboxWrapper>
                      <HandleError meta={meta} name={input.name} />
                    </CustomFormField>
                  );
                }}
              </Field>
            </>
          ))
        }
      </FieldArray>
    </>
  );
};
