import React from 'react';

import styled from 'styled-components';

const keyframes = require('styled-components').keyframes;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
}
`;

const StyleWrapper = styled.span`
  color: #4170c4;
  svg {
    animation: ${rotate} 2s linear infinite;
    z-index: 2;
    position: absolute;
  }

  circle {
    stroke: currentColor;
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`;

export const Spinner = (props: { className?: string }) => (
  <StyleWrapper data-testid="spinner" {...props}>
    <svg viewBox="0 0 50 50" fill="current">
      <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
    </svg>
  </StyleWrapper>
);
