import React, { Component } from 'react';

import BlockyListMultiSelect from './BlockyListMultiSelect';
import BlockyPopOutListMultiSelect from './BlockyPopOutListMultiSelect';
import DefaultListMultiSelect from './DefaultListMultiSelect';
import PopOutListMultiSelect from './PopOutListMultiSelect';

export type ListSelectValue = {
  valueType: string;
  displayName: string;
  value: string;
};

type OnChangeCallBack = {
  name?: string;
  currentValue: { values: string[] };
  searchQueryGroup?: string;
  filterType?: string;
};

export type ListMultiSelectProps = {
  name?: string;
  displayName?: string;
  alternativeStyle: boolean;
  showLabel: boolean;
  values: ListSelectValue[];
  currentValue?: { values: string[] };
  onChange?: ({
    name,
    currentValue,
    searchQueryGroup,
    filterType,
  }: OnChangeCallBack) => void;
  disabled?: boolean;
  searchQueryGroup?: string;
  variant: string;
  onMenuChange?: (isOpen: boolean) => void;
};

type ListMultiSelectState = { isOpen: boolean };

class VariableListMultiSelect extends Component<
  ListMultiSelectProps,
  ListMultiSelectState
> {
  state = { isOpen: false };

  shouldComponentUpdate(
    nextProps: ListMultiSelectProps,
    nextState: ListMultiSelectState,
  ) {
    return (
      this.state.isOpen !== nextState.isOpen ||
      JSON.stringify(this.props.currentValue) !==
        JSON.stringify(nextProps.currentValue)
    );
  }

  onChange = (searchFilterObject: OnChangeCallBack) => {
    this.props.onChange && this.props.onChange(searchFilterObject);
  };

  onMenuChange = (isOpen: boolean) => {
    this.setState({ isOpen });
  };

  renderComponent = (props: ListMultiSelectProps) => {
    switch (props.variant) {
      case 'DAFT_BLOCKY':
        if (props.alternativeStyle) {
          return (
            <BlockyPopOutListMultiSelect
              {...props}
              onChange={this.onChange}
              onMenuChange={this.onMenuChange}
            />
          );
        }
        return (
          <BlockyListMultiSelect
            {...props}
            onChange={this.onChange}
            aria-label={name}
          />
        );

      default:
        if (props.alternativeStyle) {
          return (
            <PopOutListMultiSelect
              {...props}
              onChange={this.onChange}
              aria-label={name}
            />
          );
        }
        return (
          <DefaultListMultiSelect
            {...props}
            onChange={this.onChange}
            aria-label={name}
          />
        );
    }
  };

  render() {
    return (
      <div
        data-testid={`multi-select-filter-${this.props.name}`}
        className={this.state.isOpen ? 'hasOpenMenu' : ''}
      >
        {this.renderComponent(this.props)}
      </div>
    );
  }
}

export default VariableListMultiSelect;
