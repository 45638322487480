import React from 'react';

import { Field } from 'react-final-form';

import { ShowWhenCondition } from '../../../services/Filters/FilterTypes';

export const Condition = (props: any) => {
  const { when, is, children } = props;
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => {
        const currentValue =
          (value?.values ? value?.values[0] : null) || value || null;
        return (Array.isArray(is) && is.indexOf(currentValue) > -1) ||
          is === currentValue
          ? children
          : null;
      }}
    </Field>
  );
};

export const NestedCondition = ({
  conditionArray,
  fieldToRender,
  fieldName,
}: {
  conditionArray: ShowWhenCondition[];
  fieldToRender: React.ReactNode;
  fieldName: string;
}) => {
  if (conditionArray.length === 0) return <>{fieldToRender}</>;
  else {
    const conditionArrayCopy: ShowWhenCondition[] = [...conditionArray];
    const fieldCondition: ShowWhenCondition =
      conditionArrayCopy.shift() as ShowWhenCondition;
    return (
      <Condition when={fieldCondition.fieldName} is={fieldCondition.is}>
        <NestedCondition
          fieldToRender={fieldToRender}
          fieldName={fieldName}
          conditionArray={conditionArrayCopy}
        />
      </Condition>
    );
  }
};
