import React, { Component, ReactNode } from 'react';

type onChangeCallBack = {
  name: string;
  searchQueryGroup: string;
  currentValue: string;
  filterType: string;
};

type TextInputState = {
  currentValue: string;
};

type TextInputProps = {
  name: string;
  currentValue: string;
  searchQueryGroup: string;
  onChange: ({
    name,
    searchQueryGroup,
    filterType,
    currentValue,
  }: onChangeCallBack) => void;
  children: (stateAndHelpers: { onChange: any }) => ReactNode;
};

class TextInput extends Component<TextInputProps, TextInputState> {
  state = {
    currentValue: this.props.currentValue,
  };

  handleChange = (event: any | React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const { onChange, name, searchQueryGroup } = this.props;
    onChange &&
      onChange({
        name,
        searchQueryGroup,
        filterType: 'TextInput',
        currentValue: value,
      });
    this.setState({ currentValue: value });
  };

  getStateAndHelpers() {
    return {
      onChange: this.handleChange,
    };
  }

  render() {
    return this.props.children(this.getStateAndHelpers());
  }
}

export { TextInput };
