import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import {
  StepperProps,
  GroupedStepperProps,
} from '../../../services/Filters/FilterTypes';

import { Stepper } from '../Stepper/Stepper';

const StyledDiv = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.M32};
`;

export const GroupedStepper = (props: GroupedStepperProps) => {
  const { name, elements, onChange, hasError } = props;

  const [currentValue, setCurrentValue] = useState(props.currentValue);

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue, onChange]);

  const handleChange = (value: { [index: string]: string }) => {
    setCurrentValue({
      ...currentValue,
      ...value,
    });
  };

  return (
    <>
      {elements.map((element: StepperProps) => {
        return (
          <StyledDiv data-testid={name} key={element.name}>
            <Stepper
              key={element.name}
              {...element}
              isGroupedStepper={true}
              currentValue={
                currentValue && currentValue[element.name]
                  ? currentValue[element.name]
                  : element.currentValue
              }
              onChange={handleChange}
              hasError={hasError}
            />
          </StyledDiv>
        );
      })}
    </>
  );
};
