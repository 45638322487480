import React from 'react';

import DelayedRender from '../../../helpers/components/DelayedRender';

import { Spinner } from './Spinner';

type DelayProps = {
  className?: string;
  isLoading: boolean;
};

export const DelayedSpinner = (props: DelayProps) => {
  const { className, isLoading } = props;
  return (
    <DelayedRender show={isLoading}>
      <Spinner className={className} {...props} />
    </DelayedRender>
  );
};
