import React, { useState } from 'react';

import styled from 'styled-components';

import { TextAreaProps } from '../../../services/Filters/FilterTypes';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextarea = styled.textarea<{
  minHeight: string;
  hasError?: boolean;
}>`
  border: 1px solid
    ${({ theme, hasError }: { theme: any; hasError?: boolean }) =>
      hasError ? theme.color.ERROR : theme.color.GREY_LIGHT};
  border-radius: ${({ theme }) => theme.border.radius.STANDARD};
  min-height: ${({ minHeight }) => minHeight};
  padding: ${({ theme }) => theme.spacing.M16};
  color: ${({ theme }) => theme.color.GREY_DARKER};
  line-height: 1.5;
  ::placeholder {
    color: ${({ theme }) => theme.color.GREY_DARK};
  }
  &:focus {
    outline: 0;
    border: 1px solid
      ${({ theme, hasError }: { theme: any; hasError?: boolean }) =>
        hasError ? theme.color.ERROR : theme.color.GREY_LIGHT};
  }
`;

export const TextArea = (props: TextAreaProps) => {
  const {
    name,
    displayHint,
    onBlur,
    onChange,
    value,
    minHeight = '40vh',
    hasError,
  } = props;

  const [currentValue, setCurrentValue] = useState(value);

  const handleBlur = () => {
    onBlur && onBlur();
    onChange && onChange(currentValue || '');
  };

  const handleChange = (event: { target: HTMLTextAreaElement }) => {
    setCurrentValue(event.target.value);
  };

  return (
    <StyledDiv data-testid={name}>
      <StyledTextarea
        data-testid="textarea"
        name={name}
        id={name}
        aria-label={`${name}: ${displayHint}`}
        cols={100}
        placeholder={displayHint}
        value={currentValue}
        onBlur={handleBlur}
        onChange={handleChange}
        minHeight={minHeight}
        hasError={hasError}
      />
    </StyledDiv>
  );
};
