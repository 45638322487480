import React, { useState, useEffect } from 'react';

import { MinusIcon, PlusIcon } from '@dsch/icons';
import styled from 'styled-components';

import { StepperProps } from '../../../services/Filters/FilterTypes';

const StyledWrapper = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.color.GREY_DARKER};
  line-height: 2.1;
  display: flex;
  justify-content: space-between;
`;

const StyledP = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
`;

const StyledDiv = styled.div`
  display: flex;
`;

const StyledMinusIcon = styled(MinusIcon)`
  height: ${({ theme }) => theme.spacing.S12};
`;

const StyledPlusIcon = styled(PlusIcon)`
  height: ${({ theme }) => theme.spacing.S12};
`;

type StyledProps = {
  isDisabled: boolean;
  hasError: boolean;
};

const StyledButton = styled.button<StyledProps>`
  background: ${({ isDisabled, hasError }: StyledProps) => {
    return hasError
      ? isDisabled
        ? ({ theme }) => theme.color.ERROR_LIGHTEST
        : ({ theme }) => theme.color.ERROR
      : isDisabled
        ? ({ theme }) => theme.color.PRIMARY_LIGHTER
        : ({ theme }) => theme.color.PRIMARY;
  }};
  color: ${({ theme }) => theme.color.WHITE};
  font-size: 24px;
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  width: ${({ theme }) => theme.spacing.M32};
  height: ${({ theme }) => theme.spacing.M32};
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.S4};
  :focus {
    outline: 1px solid #4170c4;
  }
`;

const StyledSpan = styled.span`
  font-size: 16px;
  margin-left: ${({ theme }) => theme.spacing.M16};
  margin-right: ${({ theme }) => theme.spacing.M16};
  min-width: ${({ theme }) => theme.spacing.M20};
  text-align: center;
`;

export const Stepper = (props: StepperProps) => {
  const {
    name,
    displayName,
    stepSize,
    min,
    max,
    onChange,
    isGroupedStepper,
    hasError,
  } = props;

  const [currentValue, setCurrentValue] = useState(props.currentValue);

  useEffect(() => {
    handleChange(currentValue);
    // eslint-disable-next-line
  }, [currentValue, isGroupedStepper, name]);

  const handleChange = (currentValue: number) => {
    if (isGroupedStepper) {
      onChange({
        [name]: currentValue,
      });
    } else {
      onChange(currentValue);
    }
  };

  const decrement = () => {
    if (currentValue > min) {
      setCurrentValue(currentValue - stepSize);
    }
  };

  const increment = () => {
    if (currentValue < max) {
      setCurrentValue(currentValue + stepSize);
    }
  };

  return (
    <StyledWrapper data-testid={name}>
      <StyledP>{displayName} </StyledP>
      <StyledDiv>
        <StyledButton
          type="button"
          data-testid={`${name}-decrement-button`}
          isDisabled={min >= currentValue}
          onClick={decrement}
          aria-label={`${name}-decrement`}
          hasError={hasError || false}
        >
          <StyledMinusIcon />
        </StyledButton>
        <StyledSpan data-testid={`${name}-current-value`}>
          {currentValue}
        </StyledSpan>
        <StyledButton
          type="button"
          data-testid={`${name}-increment-button`}
          isDisabled={currentValue >= max}
          onClick={increment}
          aria-label={`${name}-increment`}
          hasError={hasError || false}
        >
          <StyledPlusIcon />
        </StyledButton>
      </StyledDiv>
    </StyledWrapper>
  );
};
