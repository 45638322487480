import React, { useEffect, useState } from 'react';

import { Field } from 'react-final-form';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';

import { SubHeading } from '../../../../../components/Forms/common/SubHeading';
import {
  StyledInput,
  StyledPrefix,
  InputWrapper,
} from '../../../../../components/TermsFilter/TermsFilter';

import { Divider, CustomLine, CustomSpace } from './SaleFeatures.styled';

export const SaleFeatures = ({ initialValues }: any) => {
  const { mutators } = useForm();

  const { features } = initialValues;

  const featuresLength = 5;

  const [featuresList, setFeaturesList] = useState(
    features
      .concat(new Array(featuresLength).fill(undefined))
      .slice(0, featuresLength) || new Array(featuresLength),
  );

  const ShowOption = (value: any, previous: any, index: number) => {
    if (
      !previous &&
      value &&
      featuresList.find((item: any) => item === '') === undefined &&
      index < featuresLength - 1
    ) {
      mutators.push('featureArray', undefined);
      const arr = [...featuresList];
      arr[index] = value;
      setFeaturesList(arr);
    }
    if (value) {
      const arr = [...featuresList];
      arr[index] = value;
      setFeaturesList(arr);
    }
    if (previous && !value) {
      const arr = [...featuresList];
      arr[index] = '';
      setFeaturesList(arr);
    }
  };

  useEffect(() => {
    if (features?.length > 0) {
      features.map(() => mutators.push('featureArray', undefined));
      if (features?.length < featuresLength)
        mutators.push('featureArray', undefined);
    } else mutators.push('featureArray', undefined);
  }, []);

  return (
    <>
      <Divider />
      <SubHeading
        title="List the property’s top features"
        subtitle="List up to 5 best selling points of your property. For example: PVC double glazed windows, closed to all amenities, etc."
      />
      <FieldArray name="featureArray">
        {({ fields }) =>
          fields.map((name, index) => (
            <Field
              type="text"
              name={`feature-${index}`}
              key={`feature-${index}`}
              validate={() => {}}
              initialValue={features && features[index]}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input }) => {
                return (
                  <CustomLine>
                    <InputWrapper>
                      <StyledPrefix>{`${index + 1}. `}</StyledPrefix>
                      <StyledInput
                        id={name}
                        name={name}
                        onChange={input.onChange}
                        onBlur={input.onBlur}
                        prefix={`${index + 1}. `}
                        placeholder={index === 0 ? 'e.g. Refurbished home' : ''}
                        value={input.value}
                      />
                    </InputWrapper>
                    <OnChange name={`feature-${index}`}>
                      {(value, previous) => {
                        ShowOption(value, previous, index);
                      }}
                    </OnChange>
                  </CustomLine>
                );
              }}
            </Field>
          ))
        }
      </FieldArray>
      <CustomSpace />
    </>
  );
};
