import React from 'react';

import BlockySingleSelect from './BlockySingleSelect';
import DefaultSingleSelect from './DefaultSingleSelect';

declare type Option = {
  value: string;
  displayName: string;
};
declare type OnChangeCallBack = {
  name?: string;
  values: string[];
  searchQueryGroup?: string;
  filterType: string;
  currentValue: any;
};
export interface VariableSingleSelectProps {
  variant?: string;
  name?: string;
  values: Option[];
  id?: string;
  onChange: any;
  onBlur?: () => void;
  className?: string;
  disabled?: boolean;
  currentValue?: {
    values: string[];
  };
  showLabel?: boolean;
  searchQueryGroup?: string;
  dataTestId?: string;
  filterType: {
    name: string;
  };
  displayName: string;
  hasActiveBorder?: boolean;
}

const VariableSingleSelect = (props: VariableSingleSelectProps) => {
  const { variant, ...rest } = props;
  switch (variant) {
    case 'DAFT_BLOCKY':
      return <BlockySingleSelect {...rest} />;
    default:
      return <DefaultSingleSelect {...rest} />;
  }
};

export default VariableSingleSelect;
