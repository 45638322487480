import React from 'react';

import BedroomStepper from '../../../components/Forms/PlaceAdForm/components/BedroomStepper/BedroomStepper';
import { setCurrencyNiOption } from '../../../components/Forms/PlaceAdForm/PlaceAd.helpers';
import { BASE_URL } from '../../../helpers/utils';
import { AdState } from '../../../types/ad.types';

import { SaleFeatures } from './components/SaleFeatures/SaleFeatures';
import { SalePrice } from './components/SalePrice/SalePrice';
import { SharingPrice } from './components/SharingPrice/SharingPrice';

type PlaceAdConfigPageProps = {
  id?: string;
  title?: string;
  subTitle?: string;
  progressTitle: string;
  showProgress: boolean;
  submitButton?: string;
  inputs: any;
};

const FilterId = {
  DatePicker: 1,
  TextArea: 2,
  GroupedStepper: 3,
  Stepper: 4,
  TextInputWithDropdown: 5,
  ListSingleSelect: 6,
  ListMultiSelect: 7,
  TextInput: 8,
  DropDownRange: 9,
  select: 10,
  checkbox: 11,
  checkboxGroup: 12,
  RadioButtonGroupInput: 13,
  Hidden: 14,
  SubHeading: 15,
  Recaptcha: 16,
  FillerContent: 17,
  SharingPriceComponent: 18,
  SaleFeaturesComponent: 19,
  SalePriceComponent: 20,
};

const setAdTypeConfig: PlaceAdConfigPageProps = {
  id: 'category',
  progressTitle: 'Ad type',
  showProgress: false,
  title: 'Choose your ad type',
  subTitle: 'These are the categories that your ad is going to be listed in.',
  inputs: [
    {
      id: 1,
      name: 'type',
      displayName: 'Rent',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.RadioButtonGroupInput,
        name: 'RadioButtonGroupInput',
      },
      values: [
        { displayName: 'Residential Rent', value: 'rental' },
        { displayName: 'Sharing (Rent a room)', value: 'sharing' },
        { displayName: 'Commercial Rent', value: 'rental_commercial' },
        { displayName: 'Parking Rent', value: 'parking' },
        // { displayName: 'Holiday homes', value: 'shortterm' },
        // { displayName: 'Overseas', value: 'international_rental' },
      ],
      displayHint: '',
      required: true,
    },
    {
      id: 2,
      name: 'type',
      displayName: 'Sale',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.RadioButtonGroupInput,
        name: 'RadioButtonGroupInput',
      },
      values: [
        { displayName: 'Residential Sale', value: 'sale' },
        { displayName: 'Commercial Sale', value: 'sale_commercial' },
        { displayName: 'Parking Sale', value: 'sale_parking' },
        // { displayName: 'Overseas', value: 'sale_overseas' },
      ],
      displayHint: '',
      required: true,
    },
  ],
};

export const location: PlaceAdConfigPageProps = {
  id: 'location',
  title: 'Locate your property',
  progressTitle: 'Location',
  showProgress: true,
  inputs: [
    {
      id: 1,
      name: 'countyId',
      parentObjectName: 'location',
      displayName: 'County',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          displayName: 'Select county',
          value: '',
        },
      ],
      currentValue: '',
      required: true,
    },
    {
      id: 2,
      name: 'areaId',
      parentObjectName: 'location',
      displayName: 'Area',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          displayName: 'Select Area',
          value: '',
        },
      ],
      currentValue: '',
      required: true,
    },
    {
      id: 3,
      name: 'address',
      parentObjectName: 'location',
      displayName: 'Address',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      values: [],
      inputType: 'text',
      required: true,
      currentValue: { currentValue: '' },
      validations: {
        regexps: [
          {
            regex: '^[^@]{1,}$',
            message: '@ character not allowed',
          },
        ],
      },
      options: {
        displayHint: 'e.g. 1 Main Road',
      },
      notification:
        'Please enter your house/apartment number to help more users find your ad',
    },
    {
      id: 4,
      name: 'eircode',
      parentObjectName: 'location',
      displayName: 'Eircode',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      values: [],
      inputType: 'text',
      required: true,
      currentValue: '',
    },
    {
      id: 5,
      name: 'longitude',
      parentObjectName: 'location',
      displayName: 'Longitude',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      values: [],
      inputType: 'text',
      required: true,
      currentValue: '',
    },
    {
      id: 6,
      name: 'latitude',
      parentObjectName: 'location',
      displayName: 'Latitude',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      values: [],
      inputType: 'text',
      required: true,
      currentValue: '',
    },
  ],
};

const rentalPriceInput = (initialValues: any) => {
  return [
    {
      id: 1,
      name: 'price',
      displayName: 'Price',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInputWithDropdown,
        name: 'TextInputWithDropdown',
      },
      values: [],
      required: true,
      elements: [
        {
          id: 2,
          name: 'rentalValue',
          displayName: 'Price',
          variant: 'DAFT_DEFAULT',
          filterType: {
            id: FilterId.TextInput,
            name: 'TextInput',
          },
          required: true,
          keyboardType: 'NUMERIC',
          inputType: 'currency',
          validations: {
            regexps: [
              {
                regex: '(^[1-9]\\d*$)|(^(0)+[1-9]+$)',
                message:
                  'Please enter a weekly or monthly amount, using numbers only.',
              },
            ],
          },
          options: {
            shouldShowButton: false,
            prefix: setCurrencyNiOption(initialValues) == true ? '£' : '€',
          },
        },
        {
          id: 3,
          name: 'collectionPeriod',
          variant: 'DAFT_DEFAULT',
          filterType: {
            id: FilterId.ListSingleSelect,
            name: 'ListSingleSelect',
          },
          values: [
            {
              valueType: 'FilterStringValue',
              displayName: 'Monthly',
              value: 'monthly',
            },
            {
              valueType: 'FilterStringValue',
              displayName: 'Weekly',
              value: 'weekly',
            },
          ],
          currentValue: {
            values: ['monthly'],
          },
          required: true,
        },
      ],
    },
    {
      id: 4,
      parentObjectName: 'price',
      name: 'onApplication',
      currentValue: 'no',
      filterType: {
        id: FilterId.Hidden,
        name: 'Hidden',
      },
    },
    {
      id: 5,
      parentObjectName: 'price',
      name: 'currency',
      currentValue: setCurrencyNiOption(initialValues) == true ? 'GBP' : 'EUR',
      filterType: {
        id: FilterId.Hidden,
        name: 'Hidden',
      },
    },
  ];
};

const sharingPriceInput = (initialValues?: any) => {
  return [
    {
      id: 1,
      name: 'price',
      displayName: 'Price',
      variant: 'DAFT_DEFAULT',
      customComponent: <SharingPrice initialValues={initialValues} />,
      currentValue: [],
      filterType: {
        id: FilterId.SharingPriceComponent,
        name: 'SharingPriceComponent',
      },
      required: true,
    },
  ];
};

const salePriceInput = (initialValues?: any) => {
  return [
    {
      id: 1,
      name: 'sellingType',
      displayName: 'Selling type',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Select selling type',
          value: '',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'For Sale by Private Treaty',
          value: 'private_treaty',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'For Sale by Public Auction',
          value: 'auction',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'For Sale by Public Tender',
          value: 'tender',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'For Sale by Private Tender',
          value: 'private_tender',
        },
      ],
      currentValue: {
        values: [''],
      },
      displayHint: 'Select selling type',
      required: true,
    },
    {
      id: 2,
      name: 'price',
      displayName: 'Price',
      variant: 'DAFT_DEFAULT',
      customComponent: <SalePrice initialValues={initialValues} />,
      currentValue: [],
      filterType: {
        id: FilterId.SalePriceComponent,
        name: 'SalePriceComponent',
      },
      required: true,
    },
    {
      id: 3,
      name: 'auctionDate',
      displayName: 'Auction Date & Time',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.DatePicker,
        name: 'DatePicker',
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'sellingType',
            is: ['auction'],
          },
        ],
      },
      values: [],
      displayHint: 'Select date',
      required: false,
      options: {
        minDateEpochSecondsOffset: 0,
      },
      showTimeSelect: true,
    },
    {
      id: 4,
      name: 'auctionAddress',
      displayName: 'Auction Location',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'sellingType',
            is: ['auction'],
          },
        ],
      },
      required: false,
      keyboardType: 'DEFAULT',
      inputType: 'text',
      options: {
        shouldShowButton: false,
      },
    },
  ];
};

const parkingPriceInput = (initialValues?: any) => {
  return [
    {
      id: 1,
      name: 'priceType',
      displayName: 'For Sale or To Let',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Select Option',
          value: '',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'To Let',
          value: 'let',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'For Sale',
          value: 'sale',
        },
      ],
      currentValue: {
        values: [''],
      },
      displayHint: 'Select Option',
      required: true,
    },

    {
      id: 2,
      name: 'saleValue',
      displayName: 'Price',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      inputType: 'currency',
      conditions: {
        showWhen: [
          {
            fieldName: 'priceType',
            is: ['sale'],
          },
        ],
        disableWhen: {
          fieldName: 'saleOnApplication',
          is: true,
        },
      },
      validations: {
        regexps: [
          {
            regex: '(^[1-9]\\d*$)|(^(0)+[1-9]+$)',
            message: 'Please enter an amount, using numbers only.',
          },
        ],
        disableWhen: { fieldName: 'saleOnApplication', is: true },
      },
      options: {
        shouldShowButton: false,
        prefix: setCurrencyNiOption(initialValues) == true ? '£' : '€',
      },
      required: true,
    },
    {
      id: 3,
      name: 'saleOnApplication',
      displayName: 'Price on application',
      filterType: {
        id: FilterId.checkbox,
        name: 'checkbox',
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'priceType',
            is: ['sale'],
          },
        ],
      },
      required: false,
      disableOptionalText: true,
    },
    {
      id: 4,
      name: 'rentalValue',
      displayName: 'Price (Monthly)',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      inputType: 'currency',
      conditions: {
        showWhen: [
          {
            fieldName: 'priceType',
            is: ['let'],
          },
        ],
        disableWhen: {
          fieldName: 'letOnApplication',
          is: true,
        },
      },
      validations: {
        regexps: [
          {
            regex: '(^[1-9]\\d*$)|(^(0)+[1-9]+$)',
            message: 'Please enter an amount, using numbers only.',
          },
        ],
        disableWhen: { fieldName: 'letOnApplication', is: true },
      },
      options: {
        shouldShowButton: false,
        prefix: setCurrencyNiOption(initialValues) == true ? '£' : '€',
      },
      required: true,
    },
    {
      id: 7,
      name: 'letOnApplication',
      displayName: 'Price on application',
      filterType: {
        id: FilterId.checkbox,
        name: 'checkbox',
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'priceType',
            is: ['let'],
          },
        ],
      },
      required: false,
      disableOptionalText: true,
    },
    {
      id: 8,
      name: 'currency',
      parentObjectName: 'price',
      currentValue: setCurrencyNiOption(initialValues) == true ? 'GBP' : 'EUR',
      filterType: {
        id: FilterId.Hidden,
        name: 'Hidden',
      },
    },
  ];
};

const commercialPriceInput = (initialValues?: any) => {
  return [
    {
      id: 1,
      name: 'priceType',
      displayName: 'For Sale or To Let',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Select property type',
          value: '',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'To Let',
          value: 'let',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'For Sale',
          value: 'sale',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'To Let or For Sale',
          value: 'let_or_sale',
        },
      ],
      currentValue: {
        values: [''],
      },
      displayHint: 'Select property type',
      required: true,
    },
    {
      id: 2,
      name: 'auction',
      displayName: 'Auction',
      filterType: {
        id: FilterId.checkbox,
        name: 'checkbox',
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'priceType',
            is: ['sale', 'let_or_sale'],
          },
        ],
      },
      onChangeCondition: {
        whenIs: false,
        fieldName: 'saleOnApplication',
        changeTo: false,
      },
      required: false,
      disableOptionalText: true,
    },
    {
      id: 3,
      name: 'saleValue',
      displayName: 'Sale price',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      inputType: 'currency',
      conditions: {
        showWhen: [
          {
            fieldName: 'priceType',
            is: ['sale', 'let_or_sale'],
          },
        ],
        disableWhen: {
          fieldName: 'saleOnApplication',
          is: true,
        },
      },
      validations: {
        regexps: [
          {
            regex: '(^[1-9]\\d*$)|(^(0)+[1-9]+$)',
            message: 'Please enter an amount, using numbers only.',
          },
        ],
        // disable the validations for the condition below
        disableWhen: { fieldName: 'saleOnApplication', is: true },
      },
      options: {
        shouldShowButton: false,
        prefix: setCurrencyNiOption(initialValues) == true ? '£' : '€',
      },
      required: true,
    },
    {
      id: 4,
      name: 'saleOnApplication',
      displayName: 'Price on application',
      filterType: {
        id: FilterId.checkbox,
        name: 'checkbox',
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'priceType',
            is: ['sale', 'let_or_sale'],
          },
          {
            fieldName: 'auction',
            is: true,
          },
        ],
      },
      // when current value of this field is true, another field <fieldName>'s value will become <changeTo>
      onChangeCondition: {
        whenIs: true,
        fieldName: 'saleValue',
        changeTo: {
          currentValue: 0,
        },
      },
      required: false,
      disableOptionalText: true,
    },
    {
      id: 5,
      name: 'auctionDate',
      displayName: 'Auction Date & Time',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.DatePicker,
        name: 'DatePicker',
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'priceType',
            is: ['sale', 'let_or_sale'],
          },
          {
            fieldName: 'auction',
            is: true,
          },
        ],
      },
      values: [],
      displayHint: 'Select date',
      required: true,
      options: {
        minDateEpochSecondsOffset: 0,
      },
      showTimeSelect: true,
    },
    {
      id: 6,
      name: 'auctionAddress',
      displayName: 'Auction Location',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'priceType',
            is: ['sale', 'let_or_sale'],
          },
          {
            fieldName: 'auction',
            is: true,
          },
        ],
      },
      required: true,
      keyboardType: 'DEFAULT',
      inputType: 'text',
      options: {
        shouldShowButton: false,
      },
    },
    {
      id: 7,
      name: 'letValue',
      displayName: 'Rent price',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInputWithDropdown,
        name: 'TextInputWithDropdown',
      },
      values: [],
      required: true,
      conditions: {
        showWhen: [
          {
            fieldName: 'priceType',
            is: ['let', 'let_or_sale'],
          },
        ],
        disableWhen: {
          fieldName: 'letOnApplication',
          is: true,
        },
      },
      elements: [
        {
          id: 8,
          name: 'rentalValue',
          displayName: 'Price',
          variant: 'DAFT_DEFAULT',
          filterType: {
            id: FilterId.TextInput,
            name: 'TextInput',
          },
          required: true,
          keyboardType: 'NUMERIC',
          inputType: 'currency',
          validations: {
            regexps: [
              {
                regex: '(^[1-9]\\d*$)|(^(0)+[1-9]+$)',
                message:
                  'Please enter a weekly or monthly amount, using numbers only.',
              },
            ],
          },
          options: {
            shouldShowButton: false,
            prefix: setCurrencyNiOption(initialValues) == true ? '£' : '€',
          },
        },
        {
          id: 9,
          name: 'collectionPeriod',
          variant: 'DAFT_DEFAULT',
          filterType: {
            id: FilterId.ListSingleSelect,
            name: 'ListSingleSelect',
          },
          values: [
            {
              valueType: 'FilterStringValue',
              displayName: 'Yearly',
              value: 'yearly',
            },
            {
              valueType: 'FilterStringValue',
              displayName: 'Monthly',
              value: 'monthly',
            },
            {
              valueType: 'FilterStringValue',
              displayName: 'Weekly',
              value: 'weekly',
            },
          ],
          currentValue: {
            values: ['monthly'],
          },
          required: true,
        },
      ],
    },
    {
      id: 10,
      name: 'letOnApplication',
      displayName: 'Price on application',
      filterType: {
        id: FilterId.checkbox,
        name: 'checkbox',
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'priceType',
            is: ['let', 'let_or_sale'],
          },
        ],
      },
      required: false,
      disableOptionalText: true,
    },
    {
      id: 11,
      name: 'currency',
      parentObjectName: 'price',
      currentValue: setCurrencyNiOption(initialValues) == true ? 'GBP' : 'EUR',
      filterType: {
        id: FilterId.Hidden,
        name: 'Hidden',
      },
    },
  ];
};

const setPrice = (initialValues?: any, adType?: string) => {
  const setPriceInput = (initialValues?: any, adType?: string) => {
    if (initialValues?.type === 'sharing' || adType === 'sharing') {
      return sharingPriceInput(initialValues);
    }
    if (initialValues?.type === 'sale' || adType === 'sale') {
      return salePriceInput(initialValues);
    }
    if (initialValues?.type === 'parking' || adType === 'parking') {
      return parkingPriceInput(initialValues);
    }
    if (initialValues?.type === 'commercial' || adType === 'commercial')
      return commercialPriceInput(initialValues);
    return rentalPriceInput(initialValues);
  };
  return {
    id: 'price',
    progressTitle: 'Price',
    showProgress: true,
    title: 'Set your price',
    inputs: setPriceInput(initialValues, adType),
  };
};

const bedroomElements = [
  {
    id: 3,
    name: 'singleBeds',
    displayName: 'Single Bedrooms',
    variant: 'DAFT_DEFAULT',
    filterType: {
      id: FilterId.Stepper,
      name: 'Stepper',
    },
    values: [],
    currentValue: 0,
    required: false,
    stepSize: 1,
    min: 0,
    max: 15,
  },
  {
    id: 4,
    name: 'doubleBeds',
    displayName: 'Double Bedrooms',
    variant: 'DAFT_DEFAULT',
    filterType: {
      id: FilterId.Stepper,
      name: 'Stepper',
    },
    values: [],
    currentValue: 0,
    required: false,
    stepSize: 1,
    min: 0,
    max: 15,
  },
  {
    id: 5,
    name: 'twinBeds',
    displayName: 'Twin Bedrooms',
    variant: 'DAFT_DEFAULT',
    filterType: {
      id: FilterId.Stepper,
      name: 'Stepper',
    },
    values: [],
    currentValue: 0,
    required: false,
    stepSize: 1,
    min: 0,
    max: 15,
  },
];

const saleBedroomElements = [
  {
    id: 3,
    name: 'totalBeds',
    displayName: 'Bedrooms',
    variant: 'DAFT_DEFAULT',
    filterType: {
      id: FilterId.Stepper,
      name: 'Stepper',
    },
    values: [],
    currentValue: 0,
    required: false,
    stepSize: 1,
    min: 0,
    max: 15,
  },
];

export const bedroomValues = (initialValues?: any, adType?: string) => ({
  id: 2,
  name: 'bedrooms',
  customComponent: <BedroomStepper initialValues={initialValues} />,
  conditions: {
    showWhen: [
      {
        fieldName: 'propertyType',
        is: ['apartment', 'house', 'flat', 'bungalow', 'duplex'],
      },
    ],
  },
  currentValue: [],
  variant: 'DAFT_DEFAULT',
  filterType: {
    id: FilterId.GroupedStepper,
    name: 'GroupedStepper',
  },
  required: true,
  elements:
    initialValues?.type === 'sale' || adType === 'sale'
      ? saleBedroomElements
      : bedroomElements,
});

export const bathroomValues = {
  id: 6,
  name: 'bathrooms',
  conditions: {
    showWhen: [
      {
        fieldName: 'propertyType',
        is: ['apartment', 'house', 'flat', 'bungalow', 'duplex'],
      },
    ],
  },
  displayName: 'Bathrooms',
  variant: 'DAFT_DEFAULT',
  filterType: {
    id: FilterId.Stepper,
    name: 'Stepper',
  },
  currentValue: 1,
  required: false,
  stepSize: 1,
  min: 1,
  max: 15,
};

const setBerNumber = (id: number, platform?: string) => ({
  id: id,
  name: 'berNumber',
  displayName: 'BER no',
  variant: 'DAFT_DEFAULT',
  filterType: {
    id: FilterId.TextInput,
    name: 'TextInput',
  },
  required: false,
  keyboardType: 'NUMERIC',
  inputType: 'text',
  validations: {
    maxLength: '9',
    regexps: [
      {
        regex: '^([0-9]){9,9}$',
        message: 'BER number must be 9 digits number only!',
      },
    ],
  },
  options: {
    shouldShowButton: false,
    displayHint: 'e.g. 123456789',
  },
});

const setBerType = (id: number, platform?: string) => ({
  id: id,
  name: 'ber',
  displayName: 'BER',
  variant: 'DAFT_DEFAULT',
  filterType: {
    id: FilterId.ListSingleSelect,
    name: 'ListSingleSelect',
  },
  values: [
    {
      valueType: 'FilterStringValue',
      displayName: 'Select option',
      value: '',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'A1',
      value: 'A1',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'A2',
      value: 'A2',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'A3',
      value: 'A3',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'B1',
      value: 'B1',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'B2',
      value: 'B2',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'B3',
      value: 'B3',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'C1',
      value: 'C1',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'C2',
      value: 'C2',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'C3',
      value: 'C3',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'D1',
      value: 'D1',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'D2',
      value: 'D2',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'E1',
      value: 'E1',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'E2',
      value: 'E2',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'F',
      value: 'F',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'G',
      value: 'G',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Exempt',
      value: 'exempt',
    },
  ],
  currentValue: {
    values: [''],
  },
  displayHint: 'Select option',
  required: true,
});

const rentalDetailInput = (initialValues?: any, adType?: string) => {
  return [
    {
      id: 1,
      name: 'propertyType',
      displayName: 'Property type',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Select property type',
          value: '',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Apartment',
          value: 'apartment',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'House',
          value: 'house',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Studio',
          value: 'studio',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Flat',
          value: 'flat',
        },
      ],
      currentValue: {
        values: [''],
      },
      displayHint: 'Select property type',
      required: true,
    },
    bedroomValues(initialValues, adType),
    bathroomValues,
    {
      id: 7,
      name: 'availableFrom',
      displayName: 'Available from',
      scrollToTopOnMobile: true,
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.DatePicker,
        name: 'DatePicker',
      },
      values: [],
      displayHint: 'Select date',
      required: true,
      options: {
        minDateEpochSecondsOffset: 0,
      },
    },
    {
      id: 8,
      name: 'minimumLease',
      displayName: 'Minimum lease',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'No Minimum Lease',
          value: 0,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '3 months',
          value: 3,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '6 months',
          value: 6,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '9 months',
          value: 9,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '1 year',
          value: 12,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '2 years',
          value: 24,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '3 years',
          value: 36,
        },
      ],
      currentValue: {
        values: [0],
      },
      displayHint: 'Select option',
    },
    setBerType(9, initialValues?.platform),
    setBerNumber(10, initialValues?.platform),
  ];
};

export const propertiesInside = [
  'office',
  'retail_unit',
  'office_share',
  'industrial_unit',
  'restaurant_bar_hotel',
  'investment_property',
  'office_space',
  'serviced_office',
];

export const propertiesOutside = [
  'commercial_site',
  'agricultural_land',
  'industrial_site',
  'development_land',
];

const commercialDetailInput = (initialValues?: any) => {
  return [
    {
      id: 1,
      name: 'propertyType',
      displayName: 'Property type',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Select property type',
          value: '',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Agricultural Land',
          value: 'agricultural_land',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Commercial Site',
          value: 'commercial_site',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Development Land',
          value: 'development_land',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Industrial Site',
          value: 'industrial_site',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Industrial Unit',
          value: 'industrial_unit',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Investment Property',
          value: 'investment_property',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Office Share',
          value: 'office_share',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Office Space',
          value: 'office',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Restaurant / Bar / Hotel',
          value: 'restaurant_bar_hotel',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Retail Unit',
          value: 'retail_unit',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Serviced Office',
          value: 'serviced_office',
        },
      ],
      currentValue: {
        values: [''],
      },
      displayHint: 'Select property type',
      required: true,
    },
    {
      id: 2,
      name: 'capacity',
      displayName: 'Number of people space accommodates',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Select the number of people you have space for',
          value: '',
        },
        {
          valueType: 'FilterStringValue',
          displayName: '1 space',
          value: 1,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '2 spaces',
          value: 2,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '3 spaces',
          value: 3,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '4 spaces',
          value: 4,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '5 spaces',
          value: 5,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '6 spaces',
          value: 6,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '7 spaces',
          value: 7,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '8 spaces',
          value: 8,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '9 spaces',
          value: 9,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '10 spaces',
          value: 10,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '11 spaces',
          value: 11,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '12 spaces',
          value: 12,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '13 spaces',
          value: 13,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '14 spaces',
          value: 14,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '15 spaces',
          value: 15,
        },
      ],
      conditions: {
        showWhen: [
          {
            fieldName: 'propertyType',
            is: ['office_share'],
          },
        ],
      },
      required: true,
      keyboardType: 'DEFAULT',
      inputType: 'text',
    },
    ...(initialValues?.price?.rentalValue ||
    initialValues?.price?.rentalValue === 0
      ? [
          {
            id: 3,
            name: 'availableFrom',
            displayName: 'Available from',
            scrollToTopOnMobile: true,
            variant: 'DAFT_DEFAULT',
            filterType: {
              id: FilterId.DatePicker,
              name: 'DatePicker',
            },
            values: [],
            displayHint: 'Select date',
            required: true,
            options: {
              minDateEpochSecondsOffset: 0,
            },
          },
        ]
      : []),
    {
      id: 4,
      name: 'propertySize_in',
      displayName: 'Floor area',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInputWithDropdown,
        name: 'TextInputWithDropdown',
      },
      values: [],
      required: true,
      conditions: {
        showWhen: [
          {
            fieldName: 'propertyType',
            is: propertiesInside,
          },
        ],
      },
      elements: [
        {
          id: 5,
          name: 'size',
          displayName: 'Floor area',
          variant: 'DAFT_DEFAULT',
          filterType: {
            id: FilterId.TextInput,
            name: 'TextInput',
          },
          required: false,
          keyboardType: 'DEFAULT',
          inputType: 'text',
          validations: {
            regexps: [
              {
                regex: '^\\d+(\\.\\d{1,2})?$',
                message: 'Please enter an amount, using numbers only.',
              },
            ],
          },
          options: {
            shouldShowButton: false,
          },
        },
        {
          id: 6,
          name: 'unit',
          variant: 'DAFT_DEFAULT',
          filterType: {
            id: FilterId.ListSingleSelect,
            name: 'ListSingleSelect',
          },
          values: [
            {
              valueType: 'FilterStringValue',
              displayName: 'Square Metres',
              value: 'SQ_METRES',
            },
            {
              valueType: 'FilterStringValue',
              displayName: 'Feet',
              value: 'SQ_FEET',
            },
          ],
          currentValue: {
            values: ['SQ_METRES'],
          },
          required: true,
        },
      ],
    },
    {
      id: 4,
      name: 'propertySize_out',
      displayName: 'Floor area',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInputWithDropdown,
        name: 'TextInputWithDropdown',
      },
      values: [],
      required: true,
      conditions: {
        showWhen: [
          {
            fieldName: 'propertyType',
            is: propertiesOutside,
          },
        ],
      },
      elements: [
        {
          id: 5,
          name: 'size',
          displayName: 'Floor area',
          variant: 'DAFT_DEFAULT',
          filterType: {
            id: FilterId.TextInput,
            name: 'TextInput',
          },
          required: false,
          keyboardType: 'DEFAULT',
          inputType: 'text',
          validations: {
            regexps: [
              {
                regex: '^\\d+(\\.\\d{1,2})?$',
                message: 'Please enter an amount, using numbers only.',
              },
            ],
          },
          options: {
            shouldShowButton: false,
          },
        },
        {
          id: 6,
          name: 'unit',
          variant: 'DAFT_DEFAULT',
          filterType: {
            id: FilterId.ListSingleSelect,
            name: 'ListSingleSelect',
          },
          values: [
            {
              valueType: 'FilterStringValue',
              displayName: 'Acres',
              value: 'ACRES',
            },
            {
              valueType: 'FilterStringValue',
              displayName: 'Hectares',
              value: 'HECTARES',
            },
          ],
          currentValue: {
            values: ['ACRES'],
          },
          required: true,
        },
      ],
    },
    setBerType(7),
    setBerNumber(8),
    {
      id: 9,
      name: 'facilities',
      displayName: 'Facilities',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListMultiSelect,
        name: 'ListMultiSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'None',
          value: '',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Alarm',
          value: 'ALARM',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Parking',
          value: 'PARKING',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Meeting Rooms',
          value: 'MEETING_ROOMS',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Reception',
          value: 'RECEPTION',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Toilets',
          value: 'TOILETS',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Phone lines',
          value: 'PHONE_LINES',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Kitchen Area',
          value: 'KITCHEN_AREA',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Cat 6 Data Cabling',
          value: 'CAT_6_DATA_CABLING',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Cat 5 Cabling',
          value: 'CAT_5_CABLING',
        },
      ],
      currentValue: {
        values: [''],
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'propertyType',
            is: [
              'industrial_unit',
              'office_share',
              'office_space',
              'serviced_office',
            ],
          },
        ],
      },
      required: true,
      requiredMessage: 'notRequired',
      showLabel: false,
    },
  ];
};

const sharingDetailInput = () => {
  return [
    {
      id: 1,
      name: 'propertyType',
      displayName: 'Property type',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Select property type',
          value: '',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Apartment',
          value: 'apartment',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'House',
          value: 'house',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Flat',
          value: 'flat',
        },
      ],
      currentValue: {
        values: [''],
      },
      displayHint: 'Select property type',
      required: true,
    },
    {
      id: 2,
      name: 'availableFrom',
      displayName: 'Available from',
      scrollToTopOnMobile: true,
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.DatePicker,
        name: 'DatePicker',
      },
      values: [],
      displayHint: 'Select date',
      required: true,
      options: {
        minDateEpochSecondsOffset: 0,
      },
    },
    {
      id: 3,
      name: 'minimumLease',
      displayName: 'Available for',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Select Months',
          value: 0,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '1 month',
          value: 1,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '2 months',
          value: 2,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '3 months',
          value: 3,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '4 months',
          value: 4,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '5 months',
          value: 5,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '6 months',
          value: 6,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '7 months',
          value: 7,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '8 months',
          value: 8,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '9 months',
          value: 9,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '10 months',
          value: 10,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '11 months',
          value: 11,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '1 year',
          value: 12,
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'More than 1 year',
          value: 13,
        },
      ],
      currentValue: {
        values: [0],
      },
      required: true,
      displayHint: 'Select option',
    },
    {
      id: 4,
      name: 'tenants',
      displayName: 'Number of other tenants sharing',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Select number of tenants',
          value: 0,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '1 tenant',
          value: 1,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '2 tenants',
          value: 2,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '3 tenants',
          value: 3,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '4 tenants',
          value: 4,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '5 tenants',
          value: 5,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '6 tenants',
          value: 6,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '7 tenants',
          value: 7,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '8 tenants',
          value: 8,
        },
        {
          valueType: 'FilterStringValue',
          displayName: '9 tenants',
          value: 9,
        },
      ],
      currentValue: {
        values: [0],
      },
      displayHint: 'Select property type',
      required: true,
    },
    {
      id: 5,
      name: 'ownerOccupied',
      displayName: 'Owner occupied',
      filterType: {
        id: FilterId.checkbox,
        name: 'checkbox',
      },
      required: false,
      disableOptionalText: true,
    },
    setBerType(6),
    setBerNumber(7),
    {
      id: 8,
      name: 'gender',
      displayName: 'Preference',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.RadioButtonGroupInput,
        name: 'RadioButtonGroupInput',
      },
      values: [
        { displayName: 'Female only', value: 'female' },
        { displayName: 'Male only', value: 'male' },
        { displayName: 'Either', value: 'either' },
      ],
      displayHint: '',
      required: true,
    },
    {
      id: 9,
      name: 'couplesAllowed',
      displayName: '+1 person OK',
      filterType: {
        id: FilterId.checkbox,
        name: 'checkbox',
      },
      required: false,
      disableOptionalText: true,
    },
  ];
};

const saleDetailInput = (initialValues?: any, adType?: string) => {
  return [
    {
      id: 1,
      name: 'propertyType',
      displayName: 'Property type',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Select property type',
          value: '',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Apartment for sale',
          value: 'apartment',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Bungalow for sale',
          value: 'bungalow',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Duplex for sale',
          value: 'duplex',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'House for sale',
          value: 'house',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Site for sale',
          value: 'site',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Studio apartment for sale',
          value: 'studio',
        },
      ],
      currentValue: {
        values: [''],
      },
      displayHint: 'Select property type',
      required: true,
    },
    bedroomValues(initialValues, adType),
    bathroomValues,
    {
      id: 7,
      name: 'houseType',
      displayName: 'House type',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'propertyType',
            is: ['house'],
          },
        ],
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Select house type',
          value: '',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Detached House',
          value: 'detached',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Semi-Detached House',
          value: 'semi_detached',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Terraced House',
          value: 'terraced',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'End of Terrace House',
          value: 'end_of_terrace',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Townhouse',
          value: 'townhouse',
        },
      ],
      currentValue: {
        values: [''],
      },
      displayHint: 'Select house type',
      required: true,
    },
    {
      id: 8,
      name: 'propertySize',
      displayName: 'Floor area',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInputWithDropdown,
        name: 'TextInputWithDropdown',
      },
      values: [],
      required: false,
      elements: [
        {
          id: 9,
          name: 'size',
          variant: 'DAFT_DEFAULT',
          filterType: {
            id: FilterId.TextInput,
            name: 'TextInput',
          },
          required: false,
          keyboardType: 'DEFAULT',
          inputType: 'text',
          validations: {
            regexps: [
              {
                regex: '^\\d+(\\.\\d{1,2})?$',
                message: 'Please enter an amount, using numbers only.',
              },
            ],
          },
          options: {
            shouldShowButton: false,
          },
        },
        {
          id: 10,
          name: 'unit',
          variant: 'DAFT_DEFAULT',
          filterType: {
            id: FilterId.ListSingleSelect,
            name: 'ListSingleSelect',
          },
          values: [
            {
              valueType: 'FilterStringValue',
              displayName: 'Square Metres',
              value: 'SQ_METRES',
            },
            {
              valueType: 'FilterStringValue',
              displayName: 'Feet',
              value: 'SQ_FEET',
            },
            {
              valueType: 'FilterStringValue',
              displayName: 'Acres',
              value: 'ACRES',
            },
            {
              valueType: 'FilterStringValue',
              displayName: 'Hectares',
              value: 'HECTARES',
            },
          ],
          currentValue: {
            values: ['SQ_METRES'],
          },
          required: true,
        },
      ],
    },
    {
      id: 11,
      name: 'taxSection',
      displayName: 'Tax Designation',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Not a tax based property',
          value: '',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Section 48',
          value: 'section_48',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Section 23',
          value: 'section_23',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Section 27',
          value: 'section_27',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Section 50',
          value: 'section_50',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Holiday home',
          value: 'holiday_home',
        },
      ],
      currentValue: {
        values: [''],
      },
      displayHint: 'Select selling type',
      required: false,
    },
    {
      ...setBerType(12),
      conditions: {
        showWhen: [
          {
            fieldName: 'propertyType',
            is: ['apartment', 'bungalow', 'duplex', 'house', 'studio'],
          },
        ],
      },
    },
    {
      ...setBerNumber(13),
      conditions: {
        showWhen: [
          {
            fieldName: 'propertyType',
            is: ['apartment', 'bungalow', 'duplex', 'house', 'studio'],
          },
        ],
      },
    },
  ];
};

const parkingDetailInput = () => {
  return [
    {
      id: 1,
      name: 'parkingAccess',
      displayName: 'Access',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.RadioButtonGroupInput,
        name: 'RadioButtonGroupInput',
      },
      values: [
        { displayName: 'Business', value: 'parking_access_business_hours' },
        { displayName: '24/7', value: 'parking_access_24hour' },
      ],
      required: true,
    },
    {
      id: 2,
      name: 'capacity',
      displayName: 'Spaces Available',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      required: true,
      keyboardType: 'NUMERIC',
      inputType: 'text',
      validations: {
        maxLength: '9',
        regexps: [
          {
            regex: '(^[1-9]\\d*$)|(^(0)+[1-9]+$)',
            message: 'Spaces available must be numeric and greater than 0!',
          },
        ],
      },
      options: {
        shouldShowButton: false,
        displayHint: 'Enter a number ',
      },
    },
  ];
};

const propertyDetails = (initialValues?: any, adType?: string) => {
  const setDetailInput = (initialValues?: any, adType?: string) => {
    if (initialValues?.type === 'sharing' || adType === 'sharing') {
      return sharingDetailInput();
    }
    if (initialValues?.type === 'sale' || adType === 'sale') {
      return saleDetailInput(initialValues, adType);
    }
    if (initialValues?.type === 'commercial' || adType === 'commercial') {
      return commercialDetailInput(initialValues);
    }
    if (initialValues?.type === 'parking' || adType === 'parking') {
      return parkingDetailInput();
    }
    return rentalDetailInput(initialValues, adType);
  };
  return {
    id: 'property-details',
    progressTitle: 'Property details',
    showProgress: true,
    title: 'Add property details',
    inputs: setDetailInput(initialValues, adType),
  };
};

const facilities = (initialValues?: any, adType?: string) => {
  const facilitiesOptions = [
    {
      valueType: 'FilterStringValue',
      displayName: 'None',
      value: '',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Alarm',
      value: 'ALARM',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Cable',
      value: 'CABLE_TELEVISION',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Tumble Dryer',
      value: 'DRYER',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Dishwasher',
      value: 'DISHWASHER',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Garden / Patio / Balcony',
      value: 'GARDEN_PATIO_BALCONY',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Heating',
      value: 'CENTRAL_HEATING',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Internet',
      value: 'INTERNET',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Microwave',
      value: 'MICROWAVE',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Parking',
      value: 'PARKING',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Pets Allowed',
      value: 'PETS_ALLOWED',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Smoking',
      value: 'SMOKING',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Serviced Property',
      value: 'SERVICED_PROPERTY',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Washing Machine',
      value: 'WASHING_MACHINE',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Wheelchair Access',
      value: 'WHEELCHAIR_ACCESS',
    },
  ];

  const saleFacilitiesOptions = [
    {
      valueType: 'FilterStringValue',
      displayName: 'None',
      value: '',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Alarm',
      value: 'ALARM',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Parking',
      value: 'PARKING',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Wheelchair Access',
      value: 'WHEELCHAIR_ACCESS',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Gas Fired Central Heating',
      value: 'GAS_FIRED_CENTRAL_HEATING',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Oil Fired Central Heating',
      value: 'OIL_FIRED_CENTRAL_HEATING',
    },
    {
      valueType: 'FilterStringValue',
      displayName: 'Wired For Cable Television',
      value: 'WIRED_FOR_CABLE_TELEVISION',
    },
  ];
  const setFacilities = (
    facilitiesOptions: {
      valueType: string;
      displayName: string;
      value: string;
    }[],
    displayName: string,
  ) => ({
    id: 2,
    name: 'facilities',
    displayName: displayName,
    variant: 'DAFT_DEFAULT',
    filterType: {
      id: FilterId.ListMultiSelect,
      name: 'ListMultiSelect',
    },
    currentValue: {
      values: [''],
    },
    values: facilitiesOptions,
    required: true,
    requiredMessage: 'notRequired',
    showLabel: false,
  });

  if (initialValues?.type === 'sharing' || adType === 'sharing')
    return {
      id: 'facilities',
      progressTitle: 'Facilities',
      showProgress: true,
      title: 'Facilities',
      inputs: [setFacilities(facilitiesOptions, '')],
    };
  else if (initialValues?.type === 'sale' || adType === 'sale')
    return {
      id: 'facilities',
      progressTitle: 'Facilities & Features',
      showProgress: true,
      title: 'Facilities & Features',
      inputs: [
        setFacilities(saleFacilitiesOptions, 'Facilities'),
        {
          id: 13,
          name: 'features',
          displayName: 'Features',
          customComponent: <SaleFeatures initialValues={initialValues} />,
          filterType: {
            id: FilterId.SaleFeaturesComponent,
            name: 'SaleFeaturesComponent',
          },
          currentValue: [],
          required: true,
          requiredMessage: 'notRequired',
          showLabel: false,
        },
      ],
    };
  else
    return {
      id: 'facilities',
      progressTitle: 'Facilities',
      showProgress: true,
      title: 'Facilities',
      inputs: [
        {
          id: 1,
          name: 'furnished',
          displayName: 'Furnishing',
          variant: 'DAFT_DEFAULT',
          filterType: {
            id: FilterId.ListSingleSelect,
            name: 'ListSingleSelect',
          },
          values: [
            {
              valueType: 'FilterStringValue',
              displayName: 'Select furnishing type',
              value: '',
            },
            {
              valueType: 'FilterStringValue',
              displayName: 'Furnished',
              value: 'YES',
            },
            {
              valueType: 'FilterStringValue',
              displayName: 'Unfurnished',
              value: 'NO',
            },
            {
              valueType: 'FilterStringValue',
              displayName: 'Either available',
              value: 'OPTIONAL',
            },
          ],
          currentValue: {
            values: [''],
          },
          displayHint: 'Select furnished type',
          required: true,
        },
        setFacilities(facilitiesOptions, 'Facilities'),
      ],
    };
};

const describeProperty = (initialValues?: any) => ({
  id: 'description',
  progressTitle: 'Description',
  showProgress: true,
  title: 'Describe your property',
  subTitle:
    initialValues?.type === 'commercial' ? (
      "Please describe the location and size of your property, and call out key benefits and facilities, what's nearby, etc…"
    ) : initialValues?.type === 'parking' ? (
      <>
        Describe the location and access point (if underground or gated)
        <br />
        <br />
        Outline security, restricted access and proximity to transport or city
        centre.
        <br />
        <br />
        When is it available from and how long for?
      </>
    ) : (
      "What's great about your property? You can describe the decoration, the neighborhood, what's nearby, etc..."
    ),
  inputs: [
    {
      id: 1,
      name: 'description',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextArea,
        name: 'TextArea',
      },
      displayHint: 'Describe your property',
      required: true,
      requiredMessage: 'Please enter a description for your property',
      keyboardType: 'DEFAULT',
      inputType: 'text',
      validations: {
        regexps: [
          {
            regex: '^[^@]{1,}$',
            message: "Please don't include email addresses in your description",
          },
        ],
      },
      notification: (
        <>
          Please do not discriminate on the grounds of housing
          assistance,gender, civil status, family status, sexual orientation,
          religion,age, disability, race or traveller community.&nbsp;
          <a
            href="https://www.daft.ie/equality-guidelines"
            target="_blank"
            rel="noreferrer"
          >
            Read More.
          </a>
        </>
      ),
    },
  ],
});

const photoUpload: PlaceAdConfigPageProps = {
  id: 'media-upload',
  progressTitle: 'Media',
  showProgress: true,
  title: 'Add media',
  inputs: [
    {
      id: 1,
      name: 'videoTitle',
      displayName: '',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.SubHeading,
        name: 'SubHeading',
      },
      values: [],
      required: false,
      title: 'Add video',
      subtitle: 'Upload a video from Youtube and Vimeo',
    },
    {
      id: 2,
      name: 'videoUrl',
      displayName: 'URL link',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      required: false,
      keyboardType: 'DEFAULT',
      inputType: 'url',
      validations: {
        regexps: [
          {
            regex:
              '(^((?:https?:\\/\\/)?(?:m\\.|www\\.)?(?:youtu\\.be\\/|youtube\\.com\\/(?:embed\\/|watch\\/|watch\\?v=|watch\\?.+&v=))((\\w|-){11})((\\&*)([^=]*).*)(?:\\S+)?$))|(^(https?:\\/\\/)?(www.)?vimeo.com\\/(\\d+)$)',
            message:
              'Please provide a valid youtube/vimeo format or remove it to continue',
          },
        ],
      },
      options: {
        shouldShowButton: false,
        displayHint: 'Add your link here',
      },
    },
  ],
};

const chooseYourPlan = {
  id: 'choose-your-plan',
  progressTitle: 'Plan',
  showProgress: true,
  title: 'Choose your plan',
  inputs: [
    {
      id: 1,
      name: 'productIds',
      displayName: 'productIds',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      values: [],
      inputType: 'text',
    },
  ],
};

const contactDetails = (
  initialValues: any,
  name?: string,
  email?: string,
  phone?: string,
) => ({
  id: 'contact-details',
  progressTitle: 'Contact',
  showProgress: true,
  title: 'Contact details',
  inputs: [
    {
      id: 1,
      name: 'contactName',
      displayName: 'Full Name',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      required: true,
      keyboardType: 'DEFAULT',
      inputType: 'text',
      currentValue: name,
      validations: {
        regexps: [
          {
            regex: '^[^@|^\\d]{1,40}$',
            message:
              'Name is required, must not include digits and cannot be an email',
          },
        ],
      },
    },
    {
      id: 2,
      name: 'contactEmail',
      displayName: 'Email to receive enquiries',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      fakeDisabled: false,
      required: true,
      keyboardType: 'EMAIL',
      inputType: 'email',
      currentValue: email,
      notification: (
        <>
          You will receive enquiries on this email address, although it will not
          be publicly displayed on the ad. You may use a different email to your
          account email if required.
        </>
      ),
      validations: {
        regexps: [
          {
            regex:
              '^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,9}$',
            message: 'Please enter a valid email address',
          },
        ],
      },
    },
    {
      id: 3,
      name: 'contactPhone',
      displayName: 'Phone number',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      required: true,
      keyboardType: 'PHONE',
      inputType: 'tel',
      currentValue: phone,
      fakeDisabled: Boolean(phone),
      notification: (
        <>
          The phone number on your ad needs to be the same as your account phone
          number. If you want to change it, you may visit your {''}
          <a href={`${BASE_URL}/mydaft/my-account`}>Account Settings</a>
        </>
      ),
      validations: {
        regexps: [
          {
            regex: '^(\\+)?(\\d*[\\s()-]*)*$',
            message: 'Please enter a valid phone number',
          },
        ],
      },
    },
    {
      id: 4,
      name: 'contactPreferences',
      displayName: 'Receive enquiries for this ad through',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.ListSingleSelect,
        name: 'ListSingleSelect',
      },
      values: [
        {
          valueType: 'FilterStringValue',
          displayName: 'Both email and phone',
          value: 'both',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Email only',
          value: 'email',
        },
        {
          valueType: 'FilterStringValue',
          displayName: 'Phone only',
          value: 'phone',
        },
      ],
      currentValue: {
        values: ['both'],
      },
      notification: '',
      required: true,
    },
    {
      id: 5,
      name: 'contactInfo',
      displayName: 'Call between',
      variant: 'DAFT_DEFAULT',
      filterType: {
        id: FilterId.TextInput,
        name: 'TextInput',
      },
      conditions: {
        showWhen: [
          {
            fieldName: 'contactPreferences',
            is: ['both', 'phone'],
          },
        ],
      },
      keyboardType: 'DEFAULT',
      inputType: 'text',
      validations: {
        regexps: [
          {
            regex: '^.{1,100}$',
            message:
              'Please enter numbers, letters and special characters up to 100 characters',
          },
        ],
      },
      options: {
        displayHint: 'e.g. Mon - Fri: 9am - 5pm',
      },
      required: false,
    },
  ],
});

const reviewScreen = (initialValues?: any) => ({
  id: 'review',
  progressTitle: 'Review',
  showProgress: true,
  title: 'Review your ad listing',
  subTitle: '',
  submitButton: 'Proceed to payment',
  inputs: [],
});

type PlaceAdInitialValues = {
  initialValues?: any;
  name?: string;
  email?: string;
  phone?: string;
  adType?: string;
};

export const placeAdConfig = ({
  initialValues,
  name,
  email,
  phone,
  adType,
}: PlaceAdInitialValues) => [
  setAdTypeConfig,
  location,
  setPrice(initialValues, adType),
  propertyDetails(initialValues, adType),
  facilities(initialValues, adType),
  describeProperty(initialValues),
  photoUpload,
  chooseYourPlan,
  contactDetails(initialValues, name, email, phone),
  reviewScreen(),
];

// on edit created ad, the payment.status === PAID pages should not have the "choose-your-plan"
export const editPlaceAdConfig = ({
  initialValues,
  name,
  email,
  phone,
  adType,
}: PlaceAdInitialValues) => [
  setAdTypeConfig,
  location,
  setPrice(initialValues, adType),
  propertyDetails(initialValues, adType),
  facilities(initialValues, adType),
  describeProperty(initialValues),
  photoUpload,
  contactDetails(initialValues, name, email, phone),
  reviewScreen(),
];

export const commercialAdConfig = ({
  initialValues,
  name,
  email,
  phone,
  adType,
}: PlaceAdInitialValues) => [
  setAdTypeConfig,
  location,
  setPrice(initialValues, adType),
  propertyDetails(initialValues, adType),
  describeProperty(initialValues),
  photoUpload,
  chooseYourPlan,
  contactDetails(initialValues, name, email, phone),
  reviewScreen(),
];

// on edit created ad, the payment.status === PAID pages should not have the "choose-your-plan"
export const editCommercialAdConfig = ({
  initialValues,
  name,
  email,
  phone,
  adType,
}: PlaceAdInitialValues) => [
  setAdTypeConfig,
  location,
  setPrice(initialValues, adType),
  propertyDetails(initialValues, adType),
  describeProperty(initialValues),
  photoUpload,
  contactDetails(initialValues, name, email, phone),
  reviewScreen(),
];

export const getFields = (config: any) => {
  return config.inputs.reduce(
    (prev: any, current: any) => ({
      ...prev,
      [current.name]: current,
    }),
    {},
  );
};

export const getPageByInput = (fieldId: any, type: string) => {
  return type === 'commercial' || type === 'parking'
    ? commercialAdConfig({ adType: type }).filter((page: any) =>
        page.inputs.find((item: any) => item.name === fieldId),
      )[0]
    : editPlaceAdConfig({ adType: type }).filter((page: any) =>
        page.inputs.find((item: any) => item.name === fieldId),
      )[0];
};

export const LOCKED_ADDRESS_STATE: AdState[] = [
  'PENDING',
  'PUBLISHED',
  'SALE_AGREED',
];

export const SINGLE_SELECT_FORMAT_LIST: string[] = [
  'tenants',
  'houseType',
  'sellingType',
  'taxSection',
  'capacity',
];

export const productsMock = [
  {
    id: 31,
    brand: 'DAFT',
    name: 'Daft Residential to Rent Standard Ad',
    description: 'Promote your property on Daft.ie',
    priceIncludingVat: 75,
    vatRate: 23,
    currency: 'EUR',
    activeFrom: 1610452670750,
    productClass: 'AD',
    productCategory: 'STANDARD_AD',
    productLevel: 'STANDARD',
    siteCategory: 'RENTAL',
    upsell: false,
    active: true,
  },
  {
    id: 15,
    brand: 'DAFT',
    name: 'Daft Residential to Rent Premium Ad',
    description: 'Maximize reach and performance',
    priceIncludingVat: 99,
    vatRate: 23,
    currency: 'EUR',
    activeFrom: 1597677634435,
    productClass: 'AD',
    productCategory: 'PREMIUM_AD',
    productLevel: 'PREMIUM',
    siteCategory: 'RENTAL',
    upsell: false,
    active: true,
  },
  {
    id: 14,
    brand: 'DAFT',
    name: 'Daft Residential to Rent Featured Ad',
    description: 'The ultimate property showcase',
    priceIncludingVat: 189,
    vatRate: 23,
    currency: 'EUR',
    activeFrom: 1597677634435,
    productClass: 'AD',
    productCategory: 'FEATURED_AD',
    productLevel: 'FEATURED',
    siteCategory: 'RENTAL',
    upsell: false,
    active: true,
  },
];

export function getCommercialPageFields(
  adType: string,
  hasPaid: boolean,
  pageNumber: number,
) {
  if (hasPaid) {
    return getFields(editCommercialAdConfig({ adType })[pageNumber ?? 0]);
  } else {
    return getFields(commercialAdConfig({ adType })[pageNumber ?? 0]);
  }
}

export function getPrivatePageFields(
  adType: string,
  hasPaid: boolean,
  pageNumber: number,
) {
  if (hasPaid) {
    return getFields(editPlaceAdConfig({ adType })[pageNumber ?? 0]);
  } else {
    return getFields(placeAdConfig({ adType })[pageNumber ?? 0]);
  }
}
