import { RadioButton } from '@dsch/form-kit';
import styled from 'styled-components';

import { NewButtonTypes } from '../../../types';

export const RadioButtonWrapper = styled.label<{
  checked?: boolean;
  buttonType?: NewButtonTypes.PRIMARY | NewButtonTypes.GHOST;
  disabled?: boolean;
}>`
  text-align: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  touch-action: manipulation;
  height: 100%;

  ${({ theme, buttonType, checked, disabled }) =>
    buttonType === NewButtonTypes.PRIMARY
      ? `
      background-color: ${
        disabled ? theme.color.GREY_LIGHTEST : theme.color.WHITE
      };
      padding: ${theme.spacing.M16};

      border: ${
        checked
          ? `solid ${
              disabled
                ? `1px ${theme.color.GREY_LIGHTER}`
                : `2px ${theme.color.GREY_DARKER}`
            }`
          : `1px solid ${theme.color.GREY_LIGHTER}`
      };
      border-radius: ${theme.spacing.S4};
      `
      : ''};
`;

export const RadioButtonText = styled(({ hasError, ...rest }) => (
  <div {...rest} />
))`
  content: '';
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: ${({ theme }) => theme.spacing.S12};
`;

export const StyledRadioButton = styled(
  ({ hasError = false, displayName, ...props }) => <RadioButton {...props} />,
)`
  appearance: none;
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  min-width: 18px;
  cursor: pointer;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.WHITE};
  border: 4px solid ${({ theme }) => theme.color.WHITE};
  box-shadow: 0 0 0 1px
    ${({ theme, hasError }) =>
      hasError ? theme.color.ERROR : theme.color.GREY_LIGHT};

  &:checked {
    background: ${({ theme }) => theme.color.GREY_DARKER};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.color.GREY_DARKER};
  }

  &:disabled {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.GREY_LIGHTER};
    background: ${({ theme }) => theme.color.GREY_LIGHTEST};
    border: 4px solid ${({ theme }) => theme.color.GREY_LIGHTEST};
  }
  ${({ checked, theme }) =>
    checked &&
    `box-shadow: 0 0 0 2px ${theme.color.GREY_DARKER};
     background: ${theme.color.GREY_DARKER};
    `}
  ${({ disabled, theme }) =>
    disabled &&
    `box-shadow: 0 0 0 1px ${theme.color.GREY_LIGHTER};
     background: ${theme.color.GREY_LIGHTEST};
     border: 4px solid ${theme.color.GREY_LIGHTEST}};`}
`;

export const IconWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spacing.S12};
  display: flex;
  margin-left: auto;
`;
