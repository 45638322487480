import { mockEndpoints } from '../../config';

const isServer = () => typeof window === 'undefined';

export const getInternalEndpoint = (endpointKey: string) => {
  return `http://dsch-${process.env[endpointKey]}.shared-platform.svc:8080`;
};

export const getExternalEndpoint = ({ ext = 'ie' }: { ext: string }) => {
  return `https://gateway.daft.${ext}`;
};

export const getGatewayEndpointBasedOnEnvironment = ({
  endpointKey,
  ENVIRONMENT_NAME,
  isServer,
  INTEGRATION_TESTING = false,
  isService,
}: {
  endpointKey: string;
  ENVIRONMENT_NAME: string;
  isServer: boolean;
  INTEGRATION_TESTING?: boolean;
  isService?: boolean;
}) => {
  // when running integration tests return mountebank mock endpoint if set
  if (INTEGRATION_TESTING && mockEndpoints[endpointKey]) {
    return mockEndpoints[endpointKey];
  }

  if (isService && !isServer && ENVIRONMENT_NAME !== 'build') {
    return '';
  }

  if (isServer && ['production', 'staging'].includes(ENVIRONMENT_NAME)) {
    return getInternalEndpoint(endpointKey);
  } else {
    return getExternalEndpoint({
      ext: ENVIRONMENT_NAME === 'production' ? 'ie' : 'build',
    });
  }
};

// Use internal endpoints on production server side calls
export const getGatewayEndpoint = ({
  endpointKey,
  isService,
}: {
  endpointKey: string;
  isService?: boolean;
}) => {
  const INTEGRATION_TESTING = Boolean(
    process.env.NEXT_PUBLIC_INTEGRATION_TESTING,
  );
  const ENVIRONMENT_NAME = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME;
  return getGatewayEndpointBasedOnEnvironment({
    endpointKey,
    ENVIRONMENT_NAME,
    isServer: isServer(),
    INTEGRATION_TESTING,
    isService,
  });
};
