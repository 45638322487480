import styled from 'styled-components';

export const Divider = styled.div`
  padding: 0 0 ${({ theme }) => theme.spacing.M32} 0;
  border-top: 1px solid black;
  border-color: ${({ theme }) => theme.color.GREY_LIGHTER};
`;

export const CustomLine = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  width: 100%;
`;

export const CustomSpace = styled.div`
  padding: 0 0 ${({ theme }) => theme.spacing.M32} 0;
`;
