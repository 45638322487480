import { LocationAPI } from '../../../api';

export const fetchLocationsByType = async ({
  type,
  countyId,
}: {
  type: 'COUNTY' | 'AREA' | 'CITY';
  countyId?: string;
}) => {
  const response = await LocationAPI.lookup({
    type,
    countyId,
  });

  return response;
};

export const setCurrencyNiOption = (initialValues?: any) => {
  const NorthIrelandCounty = {
    27: 'Antrim',
    28: 'Armagh',
    32: 'Down',
    30: 'Fermanagh',
    31: 'Derry',
    29: 'Tyrone',
  };
  return initialValues?.location?.countyId in NorthIrelandCounty ? true : false;
};

export const locationValueLookup = ({
  values,
  id,
}: {
  values: any;
  id: number;
}) => {
  return values.find((item: any) => item.value == id);
};
