import styled from 'styled-components';

export const CustomErrorMessage = styled.p`
  margin-top: 0px;
  margin-bottom: ${({ theme }) => theme.spacing.M24};
  color: ${({ theme }) => theme.color.ERROR};
  font-size: 12px;
`;

export const CustomFormField = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.M32};
  width: 100%;
`;
