import React, { useEffect, useRef } from 'react';

import styled from 'styled-components';

import {
  ListMultiSelectProps,
  ListSelectValue,
} from './VariableListMultiSelect';
import { FauxCheckbox } from '../../Toolkit/Checkbox/FauxCheckbox';
import { ListMultiSelectFilter } from '../components-filter/ListMultiSelect/ListMultiSelect';
import {
  BlockyFakeSelect,
  BlockyMenuCSS,
  BlockySelect,
  DoneButton,
} from '../Styles/SharedFilterStyles';

const List = styled.ul`
  list-style-type: none;
`;

const ListItem = styled.li`
  margin-bottom: 14px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const PopOutWrapper = styled.div`
  @media only print, screen and (min-width: 704px) {
    position: relative;
  }

  &.hasOpenMenu {
    border-bottom-left-radius: 0 !important;
    border-bottom: 0 !important;
  }

  select {
    min-width: 105px;
  }
`;

const Menu = styled.div`
  ${BlockyMenuCSS};
  padding: 20px 16px 20px;
  ${(props: { showMenu?: boolean }) =>
    props.showMenu &&
    `
      display: block;
      min-width: 100vw !important;
      left: 0;
      top: 103%;
      border-bottom-width: 0;
      @media only print, screen and (min-width: 704px) {
        min-width: 150% !important;
        top: 98%;
        left: -1px;
        right: 0;
        border-bottom-width: 1px;
      }
    `};
`;

function BlockyPopOutListMultiSelect(props: ListMultiSelectProps) {
  const isOpenRef = useRef<boolean | null>(null);
  const { values, displayName, name, onMenuChange } = props;

  useEffect(() => {
    if (onMenuChange) {
      onMenuChange(isOpenRef.current || false);
    }
  }, [isOpenRef.current]);

  return (
    <ListMultiSelectFilter {...props}>
      {({
        getItemProps,
        getInputProps,
        getMenuProps,
        getToggleButtonProps,
        getLabelText,
        selectedItems,
        isOpen,
        toggleMenu,
      }: any) => {
        isOpenRef.current = isOpen;
        return (
          <PopOutWrapper className={isOpen ? 'hasOpenMenu' : ''}>
            {displayName && (
              <BlockySelect>
                <BlockyFakeSelect
                  data-testid={`multi-select-list-button-${name}`}
                  {...getToggleButtonProps()}
                >
                  {getLabelText(
                    values.filter(
                      (item: ListSelectValue) =>
                        selectedItems.filter(
                          (selectedItem: ListSelectValue) =>
                            item.value === selectedItem.value,
                        ).length > 0,
                    ),
                    displayName,
                  )}
                </BlockyFakeSelect>
              </BlockySelect>
            )}
            <Menu showMenu={isOpen} data-testid="multi-select-list-wrapper">
              <List {...getMenuProps()}>
                {values.map((item: ListSelectValue, index: number) => {
                  const itemIsChecked =
                    selectedItems.filter(
                      (selectedItem: ListSelectValue) =>
                        item.value === selectedItem.value,
                    ).length > 0;
                  return (
                    <ListItem
                      {...getItemProps({
                        index,
                        item,
                        id: `multi-select-list-item-${item.value}`,
                        key: index,
                      })}
                      data-testid={`multi-select-list-item-${item.value}`}
                      aria-selected={itemIsChecked}
                      key={index}
                    >
                      <FauxCheckbox
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                          event.preventDefault()
                        }
                        {...getInputProps({
                          id: `multi-select-item-${item.value}`,
                          name: `checkbox-item${item.value}`,
                          value: item.value,
                          checked: itemIsChecked,
                          checkboxText: item.displayName,
                        })}
                        data-testid={`multi-select-item-${item.value}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
              <DoneButton onClick={toggleMenu} />
            </Menu>
          </PopOutWrapper>
        );
      }}
    </ListMultiSelectFilter>
  );
}

export default BlockyPopOutListMultiSelect;
