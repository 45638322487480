import React from 'react';

import styled from 'styled-components';

type TermsOfUseProps = {
  boldedText?: string;
  textBefore: string;
  textAfter?: string;
  textType?: 'PRIMARY' | 'SECONDARY';
  testUUID: string;
};

const Bold = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
`;

const Paragraph = styled.p<{ textType: string }>`
  display: inherit;
  ${({ theme, textType }) =>
    textType === 'PRIMARY'
      ? `
    ${theme.fontSize.B12}
    color: ${theme.color.GREY_DARKER};
    `
      : `
        ${theme.fontSize.B10}
    color: ${theme.color.GREY_DARK};
    `}
  font-weight: ${({ theme }) => theme.fontWeight.REGULAR};
`;

const StyledLink = styled.a`
  font-weight: ${({ theme }) => theme.fontWeight.REGULAR};
  color: ${({ theme }) => theme.color.PRIMARY};
`;

export const TermsOfUse = ({
  boldedText,
  textBefore,
  textAfter,
  textType = 'PRIMARY',
  testUUID,
}: TermsOfUseProps) => (
  <Paragraph textType={textType} data-testid={`terms-of-use-${testUUID}`}>
    <Bold data-testid={`terms-of-use-bold-text-${testUUID}`}>{boldedText}</Bold>{' '}
    {textBefore}{' '}
    <StyledLink
      href="https://support.daft.ie/hc/en-ie/articles/5127313728273-Terms-and-Conditions-of-use-of-Daft"
      target="_blank"
      rel="noopener noreferrer"
      data-testid={`terms-of-use-tc-${testUUID}`}
    >
      Terms of use
    </StyledLink>{' '}
    and{' '}
    <StyledLink
      href="https://support.daft.ie/hc/en-ie/articles/360003989197-Daft-Privacy-and-Cookie-Policies"
      target="_blank"
      rel="noopener noreferrer"
      data-testid={`terms-of-use-privacy-${testUUID}`}
    >
      Privacy Policy
    </StyledLink>{' '}
    {textAfter}
  </Paragraph>
);
