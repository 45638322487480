import React, { ComponentType, ReactNode, memo } from 'react';

import dynamic from 'next/dynamic';
import { Field, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import styled from 'styled-components';

import VariableDropDownRange from '../../components/Filters/DropDownRange/VariableDropDownRange';
import { GroupedStepper } from '../../components/Filters/GroupedStepper/GroupedStepper';
import VariableListMultiSelect from '../../components/Filters/ListMultiSelect/VariableListMultiSelect';
import VariableSingleSelect from '../../components/Filters/SingleSelect/VariableSingleSelect';
import { Stepper } from '../../components/Filters/Stepper/Stepper';
import { TextArea } from '../../components/Filters/TextArea/TextArea';
import TextInputWithDropdown from '../../components/Filters/TextInputWithDropdown/TextInputWithDropdown';
import { SubHeading } from '../../components/Forms/common/SubHeading';
import { TermsFilter } from '../../components/TermsFilter/TermsFilter';
import { DaftDropdown as Dropdown } from '../../components/Toolkit//Dropdown/Dropdown';
import { Checkbox } from '../../components/Toolkit/Checkbox/Checkbox';
import { CheckboxGroup } from '../../components/Toolkit/CheckboxGroup/CheckboxGroup';
import { RadioButtonGroupInput } from '../../components/Toolkit/RadioButtonGroupInput/RadioButtonGroupInput';
import { INTEGRATION_TESTING } from '../../helpers/utils';
import {
  DatePickerProps,
  DisableWhenCondition,
  DropDownRangeProps,
  ElementTypes,
  FormField,
  GroupedStepperProps,
  Hidden,
  ListMultiSelectProps,
  ListSingleSelectProps,
  SelectProps,
  StepperProps,
  TextAreaProps,
  TextInputProps,
  TextInputWithDrodownProps,
} from '../../services/Filters/FilterTypes';

import { Condition, NestedCondition } from './common/Condition';
import { Label, StyledCheckboxWrapper } from './common/Label';
import { TermsOfUse } from './common/TermsOfUse';
import * as S from './Styles';

export type FormFieldPages = {
  title: string;
  inputs: FormField[];
};

type FormBuilderProps = {
  formFields: FormField[];
  allowSpaceForErrors?: boolean;
  initialValues?: any;
};

const DateTimePicker: ComponentType<any> = dynamic(
  import('../Toolkit/DateTimePicker/DateTimeInput').then(
    (module) => module.DateTimeInput,
  ),
  {
    ssr: false,
    loading: () => <></>,
  },
);

export const HandleError = ({ meta, name }: { meta: any; name: string }) => {
  if (
    meta?.touched &&
    !meta.dirtySinceLastSubmit &&
    (meta.error || meta.submitError)
  ) {
    return (
      <S.ErrorMessage data-testid={`${name}-validation-error`}>
        {meta.error || meta.submitError}
      </S.ErrorMessage>
    );
  }
  return null;
};

const NotificationWrapper = styled.div`
  ${({ theme }) => theme.fontSize.B12};
  margin-top: ${({ theme }) => theme.spacing.S8};
  color: ${({ theme }) => theme.color.GREY_DARK};
  a {
    color: ${({ theme }) => theme.color.PRIMARY};
    text-decoration: underline;
  }
`;

export const Notification = (props: { children: string | JSX.Element }) => {
  return <NotificationWrapper>{props.children}</NotificationWrapper>;
};

export const validation = (value: TextAreaProps['value']) => {
  return value ? undefined : 'Required';
};

const FormBuilder = memo(
  ({
    formFields,
    initialValues,
    allowSpaceForErrors = true,
  }: FormBuilderProps) => {
    // mutators: disable function requires value changing in <Field />
    const { mutators } = useForm();
    const renderFormElement = (field: FormField): JSX.Element | ReactNode => {
      const {
        name,
        value,
        values,
        title,
        subtitle,
        displayName,
        displayHint,
        filterType,
        required,
        requiredMessage,
        validations,
        labelSubText,
        inputType,
        standardTerms,
        description,
        notification,
        disableOptionalText,
        fillerContent,
        shouldFilterValues = false,
        showTimeSelect,
        filterDate,
        filterTime,
        disabled = false,
        onChangeCondition,
        isCallbackFormLQ,
        scrollToTopOnMobile = false,
      } = field as any;

      //it scroll the mobile screen until the field hits the top - used to give space for the field dropdown and the mobile keyboard
      const handleMobileScroll = (
        scrollToTopOnMobile: boolean,
        clickedElement?: HTMLElement | null,
      ) => {
        if (scrollToTopOnMobile && screen.width <= 525 && clickedElement) {
          const delayBeforeScroll = 400;
          // set a time to wait the keyboard before the scroll, because it was interfering on the scroll for the date picker
          setTimeout(() => {
            clickedElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }, delayBeforeScroll);
        }
      };

      const currentValue =
        initialValues && initialValues[field.name] !== undefined
          ? initialValues[field.name]
          : field.currentValue;

      if (field.customComponent) {
        return field.customComponent ? field.customComponent : null;
      }

      switch (filterType.name) {
        case ElementTypes.DatePicker:
          const DatePickerValidation = (currentValue: number) => {
            if (currentValue === undefined) {
              return requiredMessage || 'Required';
            }

            if (filterTime) {
              const currentDate = new Date(currentValue);
              if (
                !(currentDate.getHours() >= 9 && currentDate.getHours() <= 17)
              ) {
                return 'Please choose a timeslot within working hours';
              }
            }

            return undefined;
          };
          return (
            <Field
              type="text"
              name={name}
              key={name}
              validate={required && DatePickerValidation}
              value={currentValue}
              initialValue={currentValue}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField
                    data-testid={`input-field-${name}`}
                    onClick={(event: { currentTarget: any }) =>
                      handleMobileScroll(
                        scrollToTopOnMobile,
                        event.currentTarget,
                      )
                    }
                  >
                    {displayName && (
                      <Label
                        htmlFor={name}
                        displayName={displayName}
                        optional={!required}
                        subText={labelSubText}
                      />
                    )}
                    <DateTimePicker
                      {...(field as DatePickerProps)}
                      placeholderText={displayHint}
                      showLabel={false}
                      currentValue={input.value}
                      onChange={input.onChange}
                      onBlur={input.onBlur}
                      showTimeSelect={showTimeSelect}
                      filterDate={filterDate}
                      filterTime={filterTime}
                      isCallbackFormLQ={isCallbackFormLQ}
                    />
                    <HandleError meta={meta} name={input.name} />
                    {notification && (
                      <Notification>{notification}</Notification>
                    )}
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.TextArea:
          const textAreaRegex =
            validations.regexps && new RegExp(validations.regexps[0].regex);
          const textAreaMessage =
            validations.regexps && validations.regexps[0].message;
          const TextAreaValidation = (value: { currentValue: any }) => {
            const currentValue = value?.currentValue || value;
            const matchesRegex = textAreaRegex?.test(currentValue);

            if (required && currentValue === undefined)
              return requiredMessage || 'Required';

            if (!required && currentValue === undefined) return undefined;

            return currentValue && matchesRegex ? undefined : textAreaMessage;
          };

          return (
            <Field
              type="text"
              name={name}
              key={name}
              validate={
                required || textAreaRegex ? TextAreaValidation : undefined
              }
              component="textarea"
              value={currentValue}
              initialValue={currentValue}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField data-testid={`input-field-${name}`}>
                    {displayName && (
                      <Label
                        htmlFor={name}
                        displayName={displayName}
                        optional={!required}
                        subText={labelSubText}
                      />
                    )}
                    <TextArea
                      {...(field as TextAreaProps)}
                      onChange={input.onChange}
                      onBlur={input.onBlur}
                      value={input.value || currentValue}
                      hasError={
                        meta?.touched &&
                        !meta.dirtySinceLastSubmit &&
                        Boolean(meta.error || meta.submitError)
                      }
                    />
                    <HandleError meta={meta} name={input.name} />
                    {notification && (
                      <Notification>{notification}</Notification>
                    )}
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.GroupedStepper:
          const GroupedStepperValidation = (currentValue: any) => {
            return Object.values(currentValue).filter((num) => num === 0).length
              ? requiredMessage || 'Required'
              : undefined;
          };
          return (
            <Field
              name={name}
              value={currentValue}
              key={name}
              validate={required && GroupedStepperValidation}
              initialValue={currentValue}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField data-testid={`input-field-${name}`}>
                    <GroupedStepper
                      {...(field as GroupedStepperProps)}
                      currentValue={input.value}
                      onChange={input.onChange}
                    />
                    <HandleError meta={meta} name={input.name} />
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.Stepper:
          const StepperValidation = (currentValue: number) => {
            return currentValue === 0
              ? requiredMessage || 'Required'
              : undefined;
          };
          return (
            <Field
              type="number"
              name={name}
              value={currentValue}
              key={name}
              validate={required && StepperValidation}
              initialValue={currentValue}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField data-testid={`input-field-${name}`}>
                    <Stepper
                      {...(field as StepperProps)}
                      currentValue={input.value}
                      onChange={input.onChange}
                    />
                    <HandleError meta={meta} name={input.name} />
                    {notification && (
                      <Notification>{notification}</Notification>
                    )}
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.TextInputWithDropdown:
          const termsFilterProps = (field as TextInputWithDrodownProps)
            .elements[0];
          const singleSelectProps = (field as TextInputWithDrodownProps)
            .elements[1];
          const termsFilterRegex =
            termsFilterProps.validations &&
            termsFilterProps.validations.regexps &&
            termsFilterProps.validations.regexps[0].regex
              ? new RegExp(
                  termsFilterProps.validations &&
                    termsFilterProps.validations.regexps &&
                    termsFilterProps.validations.regexps[0].regex,
                )
              : '';
          const termsFilterMessage =
            termsFilterProps.validations &&
            termsFilterProps.validations?.regexps
              ? termsFilterProps.validations?.regexps[0].message
              : '';

          const TextInputWithDropdownValidation = (value: any) => {
            const TermFilterValue = value && value[termsFilterProps.name];
            const SingleSelectValue = value && value[singleSelectProps.name];
            const MatchesRegex =
              termsFilterRegex && termsFilterRegex.test(TermFilterValue);
            if ((!required && !TermFilterValue) || disabled) return undefined;
            if (
              required &&
              ((termsFilterProps.required &&
                value[termsFilterProps.name] == '') ||
                (singleSelectProps.required &&
                  value[singleSelectProps.name] == ''))
            )
              return requiredMessage || termsFilterMessage || 'Required';
            return TermFilterValue && SingleSelectValue && MatchesRegex
              ? undefined
              : termsFilterMessage;
          };

          const initialValue = {
            [termsFilterProps.name]:
              (currentValue && currentValue[termsFilterProps.name]) ||
              termsFilterProps.currentValue ||
              '',
            [singleSelectProps.name]:
              (currentValue && currentValue[singleSelectProps.name]) ||
              singleSelectProps.currentValue.values[0],
          };

          return (
            <Field
              type="select"
              name={name as string}
              key={name}
              validate={TextInputWithDropdownValidation}
              initialValue={initialValue}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField data-testid={`input-field-${name}`}>
                    {displayName && (
                      <Label
                        htmlFor={name}
                        displayName={displayName}
                        optional={!required}
                        subText={labelSubText}
                      />
                    )}
                    <TextInputWithDropdown
                      name={(field as TextInputWithDrodownProps).name}
                      termsFilterProps={{
                        ...termsFilterProps,
                      }}
                      singleSelectProps={singleSelectProps}
                      onChange={input.onChange}
                      onBlur={input.onBlur}
                      value={input.value}
                      disabled={disabled}
                    />
                    <HandleError meta={meta} name={input.name} />
                    {notification && (
                      <Notification>{notification}</Notification>
                    )}
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.ListSingleSelect:
          const ListSingleSelectValidation = (value: any) => {
            const response =
              (value?.currentValue && value.currentValue.values[0]) ||
              value?.values[0];
            return response ? undefined : requiredMessage || 'Required';
          };

          return (
            <Field
              type="select"
              name={(field as ListSingleSelectProps).name}
              value={currentValue}
              validate={required && ListSingleSelectValidation}
              initialValue={currentValue}
              key={name}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField data-testid={`input-field-${name}`}>
                    {displayName && (
                      <Label
                        htmlFor={name}
                        displayName={displayName}
                        optional={!required}
                        subText={labelSubText}
                      />
                    )}
                    <VariableSingleSelect
                      {...(field as ListSingleSelectProps)}
                      dataTestId={(field as ListSingleSelectProps).name}
                      currentValue={input.value.currentValue || currentValue}
                      showLabel={false}
                      onChange={input.onChange}
                      onBlur={input.onBlur}
                    />
                    <HandleError meta={meta} name={input.name} />
                    {notification && (
                      <Notification>{notification}</Notification>
                    )}
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.ListMultiSelect:
          const ListMultiSelectValidation = (value: any) => {
            return value?.currentValue && value.currentValue.values[0] != ''
              ? undefined
              : requiredMessage == 'notRequired'
                ? undefined
                : 'required';
          };
          return (
            <Field
              type="select"
              name={(field as ListMultiSelectProps).name}
              value={currentValue}
              validate={required && ListMultiSelectValidation}
              initialValue={currentValue}
              key={name}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField data-testid={`input-field-${name}`}>
                    {displayName && (
                      <Label
                        htmlFor={name}
                        displayName={displayName}
                        optional={!required}
                        subText={labelSubText}
                      />
                    )}
                    <VariableListMultiSelect
                      {...(field as ListMultiSelectProps)}
                      currentValue={input.value.currentValue || currentValue}
                      alternativeStyle={false}
                      onChange={input.onChange}
                    />
                    <HandleError meta={meta} name={input.name} />
                    {notification && (
                      <Notification>{notification}</Notification>
                    )}
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.TextInput:
          const COMPARISON_TYPE = validations?.comparison?.type;
          const COMPARISON_AMOUNT = validations?.comparison?.amount;
          const COMPARISON_MESSAGE = validations?.comparison?.message;
          const CUSTOM_COMPARISON = validations?.customComparison;
          const REGEX = new RegExp(validations?.regexps[0].regex);
          const MESSAGE =
            validations?.regexps[0].message || requiredMessage || 'Required';
          const disableValidation: any = validations?.disableWhen;

          const TextInputValidation = (
            value: { currentValue: any },
            allValues: any,
          ) => {
            let currentValue = value?.currentValue || value;
            if (value?.currentValue == '') currentValue = '';
            const MatchesRegex = REGEX.test(currentValue);

            let isValidComparison;

            if (COMPARISON_TYPE) {
              switch (COMPARISON_TYPE) {
                case '>=':
                  if (currentValue >= COMPARISON_AMOUNT)
                    isValidComparison = true;
                  break;
                case '<=':
                  if (currentValue <= COMPARISON_AMOUNT)
                    isValidComparison = true;
                  break;
                default:
                  isValidComparison = false;
                  break;
              }
            }

            if (
              disableValidation &&
              allValues[disableValidation?.fieldName] === disableValidation?.is
            ) {
              return undefined;
            }

            if (!required && !currentValue) {
              return undefined;
            }

            if (!MatchesRegex || !currentValue) {
              return MESSAGE;
            }

            if (CUSTOM_COMPARISON) {
              return CUSTOM_COMPARISON(currentValue);
            }

            if ((COMPARISON_TYPE && !isValidComparison) || !currentValue) {
              return COMPARISON_MESSAGE;
            }

            return undefined;
          };

          return (
            <Field
              type="text"
              name={(field as TextInputProps).name}
              value={currentValue}
              initialValue={currentValue}
              validate={required || REGEX ? TextInputValidation : undefined}
              key={name}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField
                    spacer={allowSpaceForErrors}
                    hidden={inputType === 'hidden'}
                    data-testid={`input-field-${name}`}
                  >
                    {displayName && (
                      <Label
                        htmlFor={name}
                        displayName={displayName}
                        optional={!required}
                        subText={labelSubText}
                      />
                    )}
                    <TermsFilter
                      {...(field as TextInputProps)}
                      currentValue={input.value.currentValue || currentValue}
                      onChange={input.onChange}
                      onBlur={input.onBlur}
                      searchQueryGroup="terms"
                      inputType={inputType}
                      disabled={disabled}
                    />
                    <HandleError meta={meta} name={input.name} />
                    {notification && (
                      <Notification>{notification}</Notification>
                    )}
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.DropDownRange:
          const DropDownRangeValidation = (value: any) => {
            return !value ||
              value?.currentValue.from == '' ||
              value?.currentValue.to == ''
              ? requiredMessage || 'Required'
              : undefined;
          };
          return (
            <Field
              type="text"
              name={(field as DropDownRangeProps).name}
              value={currentValue}
              initialValue={currentValue}
              validate={required && DropDownRangeValidation}
              key={name}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField
                    spacer={allowSpaceForErrors}
                    data-testid={`input-field-${name}`}
                  >
                    {displayName && (
                      <Label
                        htmlFor={name}
                        displayName={displayName}
                        optional={!required}
                        subText={labelSubText}
                      />
                    )}
                    <VariableDropDownRange
                      {...(field as DropDownRangeProps)}
                      currentValue={input.value || currentValue}
                      onChange={input.onChange}
                      searchQueryGroup="ranges"
                      alternativeStyle={false}
                    />
                    <HandleError meta={meta} name={input.name} />
                    {notification && (
                      <Notification>{notification}</Notification>
                    )}
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.select:
          return (
            <Field
              type="select"
              name={(field as SelectProps).name}
              value={value}
              key={name}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input, meta }) => (
                <S.FormField data-testid={`input-field-${name}`}>
                  {displayName && (
                    <Label
                      htmlFor={name}
                      displayName={displayName}
                      optional={!required}
                      subText={labelSubText}
                    />
                  )}
                  <Dropdown
                    name={input.name}
                    id={input.name}
                    options={(field as SelectProps).options}
                    value={input.value}
                    onChange={input.onChange}
                    hasError={
                      meta &&
                      !meta.dirtySinceLastSubmit &&
                      Boolean(meta.error || meta.submitError)
                    }
                  />
                  <HandleError meta={meta} name={input.name} />
                </S.FormField>
              )}
            </Field>
          );
        case ElementTypes.checkbox:
          const CheckboxValidation = (currentValue: boolean) => {
            return !currentValue ? requiredMessage || 'Required' : undefined;
          };
          return (
            <Field
              type="checkbox"
              name={name as string}
              key={name}
              validate={required && CheckboxValidation}
              initialValue={initialValues?.[name]}
              value={value}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                touched: true,
                submitError: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField data-testid={`input-field-${name}`}>
                    <S.CheckboxWrapper>
                      <Checkbox
                        name={input.name}
                        id={input.name}
                        onChange={input.onChange}
                        checked={input.checked}
                        checkboxText={
                          standardTerms ? (
                            <StyledCheckboxWrapper>
                              <TermsOfUse
                                textBefore="I have read and agree to the Daft.ie"
                                testUUID={`${name}-terms-of-use`}
                              />
                            </StyledCheckboxWrapper>
                          ) : (
                            displayName
                          )
                        }
                        isOptional={!disableOptionalText && !required}
                        htmlFor={name}
                        hasError={
                          meta?.touched &&
                          !meta.dirtySinceLastSubmit &&
                          Boolean(meta.error || meta.submitError)
                        }
                      />
                    </S.CheckboxWrapper>
                    {onChangeCondition && (
                      <OnChange name={name}>
                        {(value: boolean) => {
                          value === onChangeCondition.whenIs &&
                            mutators.setValue(
                              onChangeCondition.fieldName,
                              onChangeCondition.changeTo,
                            );
                        }}
                      </OnChange>
                    )}
                    <HandleError meta={meta} name={input.name} />
                    {notification && (
                      <Notification>{notification}</Notification>
                    )}
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.checkboxGroup:
          const mustCheckAtLeastOne = (currentValue: any[]) => {
            return !currentValue?.some((value) => value)
              ? requiredMessage || 'Required'
              : undefined;
          };
          return (
            <Field
              type="checkbox"
              name={name as string}
              key={name}
              validate={required && mustCheckAtLeastOne}
              initialValue={initialValues?.[name]}
              value={value}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                touched: true,
                submitError: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField data-testid={`input-field-${name}`}>
                    <CheckboxGroup
                      checkboxInputs={values}
                      onChange={input.onChange}
                      shouldFilterValues={shouldFilterValues}
                    />
                    <HandleError meta={meta} name={input.name} />
                    {notification && (
                      <Notification>{notification}</Notification>
                    )}
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.RadioButtonGroupInput:
          const radioButtonGroupInputValidation = (
            value: TextAreaProps['value'],
          ) => {
            return typeof value !== 'undefined'
              ? undefined
              : requiredMessage || 'Required';
          };
          return (
            <Field
              type="text"
              name={name}
              key={name}
              validate={required && radioButtonGroupInputValidation}
              initialValue={currentValue}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input, meta }) => {
                return (
                  <S.FormField data-testid={`input-field-${name}`}>
                    {displayName && (
                      <Label
                        htmlFor={name}
                        displayName={displayName}
                        optional={!required}
                        subText={labelSubText}
                      />
                    )}
                    <RadioButtonGroupInput
                      value={input.value}
                      name={input.name}
                      values={values}
                      onChange={input.onChange}
                      description={description}
                    />
                    <HandleError meta={meta} name={input.name} />
                    {notification && (
                      <Notification>{notification}</Notification>
                    )}
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.Hidden:
          return (
            <Field
              type="text"
              name={(field as Hidden).name}
              value={currentValue}
              initialValue={currentValue}
              key={name}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            >
              {({ input }) => (
                <input hidden={true} name={input.name} value={input.value} />
              )}
            </Field>
          );
        case ElementTypes.SubHeading:
          return (
            <Field name={name} key={name}>
              {() => {
                return (
                  <S.FormField data-testid={`input-field-${name}`} hidden>
                    <SubHeading title={title} subtitle={subtitle} />
                  </S.FormField>
                );
              }}
            </Field>
          );
        case ElementTypes.Recaptcha:
          return (
            <Field
              type={name}
              name={name}
              key={name}
              validate={!INTEGRATION_TESTING && required && validation}
              value={value}
              subscription={{
                value: true,
                active: true,
                error: true,
                dirtySinceLastSubmit: true,
                submitError: true,
                touched: true,
              }}
            />
          );
        case ElementTypes.FillerContent:
          return (
            <Field name={name} key={name}>
              {() => {
                return (
                  <S.FormField data-testid={`input-field-${name}`} hidden>
                    {fillerContent && fillerContent}
                  </S.FormField>
                );
              }}
            </Field>
          );
        default:
          return <></>;
      }
    };

    const FormFieldElement = ({ field }: { field: FormField }) => {
      return (
        <div id={`input-wrapper-${field.name}`} key={field.name}>
          {renderFormElement(field)}
          {field.conditionalFields?.map((condition: any, index: number) => {
            return (
              <Condition
                key={`condition-${field.name}-${index}`}
                when={field.name}
                is={condition.valueName}
              >
                {condition.fields.map((item: FormField, index: number) => {
                  return (
                    <div key={`conditional-${item.name}-${index}`}>
                      <FormFieldElement field={item} />
                    </div>
                  );
                })}
              </Condition>
            );
          })}
        </div>
      );
    };

    // This component wraps the <FormFieldElement /> to pass in an extra disabled prop
    const DisabledCondition = ({
      field,
      disabledCondition,
    }: {
      field: FormField;
      disabledCondition: DisableWhenCondition;
    }) => {
      return (
        <Field
          name={disabledCondition.fieldName}
          initialValue={initialValues[disabledCondition.fieldName]}
          subscription={{ value: true }}
        >
          {({ input: { value } }) => {
            const currentValue =
              (value?.values ? value?.values[0] : null) || value || null;
            // if the currentValue of <fieldName> in disabledCondition is equal to <is> value
            return currentValue === disabledCondition.is ? (
              <FormFieldElement
                key={field.name}
                field={{ ...field, disabled: true }}
              />
            ) : (
              <FormFieldElement key={field.name} field={field} />
            );
          }}
        </Field>
      );
    };

    return (
      <>
        {formFields.map((field: FormField, index) => {
          const { conditions } = field;
          return (
            <NestedCondition
              conditionArray={conditions?.showWhen || []}
              fieldToRender={
                conditions?.disableWhen ? (
                  <DisabledCondition
                    key={field.name}
                    field={field}
                    disabledCondition={conditions.disableWhen}
                  />
                ) : (
                  <FormFieldElement key={field.name} field={field} />
                )
              }
              fieldName={field.name}
              key={`nested-${field.name}-${index}`}
            />
          );
        })}
      </>
    );
  },
);

export default FormBuilder;
