import {
  placeAdConfig,
  commercialAdConfig,
} from '../components/Forms/PlaceAdForm/PlaceAdForm.config';
import { SearchFilterTypes } from '../services/Filters/FilterTypes';
import { AdPrice, roomPrice } from '../types/ad.types';

export const getAdType = (adTypeString: string) => {
  if (adTypeString.indexOf('_') > 0) {
    return adTypeString.split('_')[1];
  } else {
    return adTypeString;
  }
};

export const transformAdResponse = ({ data }: any) => {
  const config =
    data.type === 'commercial' || data.type === 'parking'
      ? commercialAdConfig({})
      : placeAdConfig({});
  const listSelects = config
    .map(({ inputs }) => {
      const fields = inputs.filter(
        ({ filterType }: { filterType: { name: SearchFilterTypes } }) =>
          filterType.name === SearchFilterTypes.ListSingleSelect ||
          filterType.name === SearchFilterTypes.ListMultiSelect,
      );
      return fields.map((item: any) => item.name);
    })
    .filter((item) => item.length > 0)
    .reduce((prev, current) => prev.concat(current));

  const dropdownFieldKeys = ['areaId', 'countyId'];
  return Object.keys(data).reduce(
    (attrs, key) => ({
      ...attrs,
      [key]:
        listSelects.includes(key) || dropdownFieldKeys.includes(key)
          ? { values: typeof data[key] === 'object' ? data[key] : [data[key]] }
          : data[key],
    }),
    {},
  );
};

export const tranformLocationLookup = (data: any) => {
  return data.map(
    (item: {
      id: number;
      friendlyName: string;
      latitude: number;
      longitude: number;
    }) => {
      return {
        displayName: item.friendlyName,
        value: item.id,
        latitude: item.latitude,
        longitude: item.longitude,
      };
    },
  );
};

export const getParam = ({
  adType,
  price,
  upgrade,
}: {
  adType: string;
  price: AdPrice;
  upgrade?: boolean;
}) => {
  let unitPrice = undefined;

  const upgradeFormat = (paramString: string) => {
    const index = paramString.indexOf('&price');
    return upgrade
      ? `${paramString.slice(0, index)}_upgrade${paramString.slice(index)}`
      : paramString;
  };

  const setPriceValue = (
    period: AdPrice['collectionPeriod'],
    unitPrice: number | undefined,
  ) => {
    return period === 'weekly'
      ? Number(unitPrice) * 4.348
      : period === 'yearly'
        ? (Number(unitPrice) / 12).toFixed(3)
        : unitPrice;
  };

  if (adType === 'rental') unitPrice = price.rentalValue;

  if (adType === 'sharing')
    unitPrice = price.bedrooms?.reduce(
      (total: number, curr: roomPrice) => total + Number(curr.rentalValue),
      0,
    );

  if (adType === 'sale') {
    if (price.onApplication === 'yes') {
      return upgradeFormat(`type=sale&price=999999`); // When user selects 'POA' instead of entering a price we set them in the highest price bracket
    }
    return upgradeFormat(`type=sale&price=${price.saleValue}`);
  }

  if (adType === 'commercial') {
    const saleValueExist = Boolean(price?.saleValue || price?.saleValue === 0);
    const rentalValueExist = Boolean(
      price?.rentalValue || price?.rentalValue === 0,
    );
    if (saleValueExist && rentalValueExist) {
      return upgradeFormat(
        `type=commercial_rent&price=${setPriceValue(
          price.collectionPeriod,
          price.rentalValue,
        )}`,
      );
    } else if (saleValueExist) {
      return upgradeFormat(`type=commercial_sale&price=${price.saleValue}`);
    } else if (rentalValueExist) {
      return upgradeFormat(
        `type=commercial_rent&price=${setPriceValue(
          price.collectionPeriod,
          price.rentalValue,
        )}`,
      );
    }
  }

  if (adType === 'parking') {
    const saleValueExist = Boolean(price?.saleValue || price?.saleValue === 0);
    const rentalValueExist = Boolean(
      price?.rentalValue || price?.rentalValue === 0,
    );
    if (saleValueExist) {
      return upgradeFormat(`type=parking_sale&price=${price?.saleValue}`);
    } else if (rentalValueExist) {
      return upgradeFormat(
        `type=parking_rent&price=${setPriceValue(
          price?.collectionPeriod,
          price?.rentalValue,
        )}`,
      );
    }
  }

  return upgradeFormat(
    `type=${adType}&price=${setPriceValue(price.collectionPeriod, unitPrice)}`,
  );
};
