import { daftApiGateway, handleApiError } from '../../api';
import { tranformLocationLookup } from '../../api/helpers';
import { baseHeaders } from '../../helpers/utils';

export const autocomplete = async ({
  text,
}: {
  text: string;
}): Promise<any> => {
  const headers = {
    ...baseHeaders,
  };
  try {
    const { data } = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v1/locations/autocomplete?query=${text}`,
    });

    return [...data];
  } catch (error) {
    return handleApiError(error);
  }
};

export const lookup = async ({
  type,
  countyId,
}: {
  type: string;
  countyId?: string;
}): Promise<any> => {
  const headers = {
    ...baseHeaders,
  };
  try {
    const { data } = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v1/locations/areas?areaType=${type}${
        countyId ? `&parentId=${countyId}` : ''
      }`,
    });
    return [...tranformLocationLookup(data)];
  } catch (error) {
    return handleApiError(error);
  }
};

export const getAreaById = async ({
  areaId,
}: {
  areaId: string;
}): Promise<any> => {
  const headers = {
    ...baseHeaders,
  };

  try {
    const { data } = await daftApiGateway({
      headers,
      method: 'get',
      url: `/api/v1/locations/areas/${areaId}`,
    });

    return data;
  } catch (error) {
    return handleApiError(error);
  }
};
