import React, { Component } from 'react';

type DelayProps = {
  show: boolean;
  className?: string;
  timeout?: number;
  children: any;
};

class DelayedRender extends Component<DelayProps> {
  state = { show: false };
  timeout: any = null;

  componentDidMount() {
    this.setTimeout();
  }

  componentDidUpdate(prevProps: DelayProps) {
    const { show } = this.props;
    if (show === prevProps.show) {
      return;
    }

    this.setTimeout();

    if (!show && this.timeout) {
      clearTimeout(this.timeout);
      this.setState({ show: false });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  setTimeout() {
    const { show, timeout } = this.props;
    if (show) {
      this.timeout = setTimeout(
        () => {
          this.setState({ show: true });
        },
        timeout ? timeout : 750,
      );
    }
  }

  render() {
    return <>{this.state.show ? this.props.children : null}</>;
  }
}

export default DelayedRender;
