import React, { ReactNode } from 'react';

import styled from 'styled-components';

import * as S from '../../../components/Forms/Styles';

import { PAGE_BREAK_POINT_800 } from '../BuyingBudgetForm/CommonStyles';

type FormHeadingProps = {
  title?: string;
  subtitle?: string;
  image?: string;
  signInPrompt?: ReactNode;
  labelText?: string;
};

const H2 = styled.h2<{ hasSubtitle: boolean }>`
  color: ${({ theme }) => theme.color.GREY_DARKER};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
  ${({ theme }) => theme.fontSize.S16};
  margin: ${(props) => (props.hasSubtitle ? '0 0 4px' : '0 0 16px')};

  @media only screen and (min-width: ${PAGE_BREAK_POINT_800}) {
    padding-right: 0;
    ${({ theme }) => theme.fontSize.T18};
  }
`;

const StyledP = styled.p`
  color: ${({ theme }) => theme.color.GREY_DARK};
  ${({ theme }) => theme.fontSize.S14};

  @media only screen and (min-width: ${PAGE_BREAK_POINT_800}) {
    ${({ theme }) => theme.fontSize.S16};
  }
`;

const SubTitle = styled.div`
  flex: 1;
`;

const SubtitleAndPageBGContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HeadingContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  @media only screen and (min-width: ${PAGE_BREAK_POINT_800}) {
    margin-bottom: ${({ theme }) => theme.spacing.M24};
  }
`;

export const SubHeading = ({ title, subtitle }: FormHeadingProps) => {
  return subtitle ? (
    <HeadingContainer>
      {title && (
        <H2
          hasSubtitle={Boolean(subtitle)}
          data-testid="page-section-title"
          id="page-title"
        >
          {title}
        </H2>
      )}
      <SubtitleAndPageBGContainer>
        {subtitle && (
          <SubTitle data-testid="page-sub-title">
            <StyledP
              data-testid="page-sub-title-paragraph"
              id="page-sub-title-paragraph"
            >
              {subtitle}
            </StyledP>
          </SubTitle>
        )}
      </SubtitleAndPageBGContainer>
    </HeadingContainer>
  ) : (
    <S.H1>{title}</S.H1>
  );
};
