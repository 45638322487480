import React, { useState, useMemo } from 'react';

import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import styled from 'styled-components';

import { HandleError } from '../../../../../components/Forms/FormBuilder';
import { Notification } from '../../../../../components/Forms/FormBuilder';
import { setCurrencyNiOption } from '../../../../../components/Forms/PlaceAdForm/PlaceAd.helpers';
import * as S from '../../../../../components/Forms/Styles';
import { TermsFilter } from '../../../../../components/TermsFilter/TermsFilter';
import { Checkbox } from '../../../../../components/Toolkit/Checkbox/Checkbox';

import { CustomFormField } from './SalePrice.styled';

const StyledSpan = styled.span`
  font-weight: 700;
  display: inline;
`;

const priceValidation = (value: { currentValue: any }) => {
  const response = value?.currentValue;
  const matchesRegex = new RegExp('(^[1-9]\\d*$)|(^(0)+[1-9]+$)').test(
    response,
  );

  if (!matchesRegex)
    return 'Please enter a valid amount great than 0, using numbers only.';

  return response ? undefined : 'This field is required';
};

export const SalePrice = ({ initialValues }: any) => {
  const { currency, onApplication, saleValue } = initialValues?.price || {};

  const [isOnApplication, setIsOnApplication] = useState(
    onApplication === 'yes' ? true : false,
  );

  const saleValueInitial = useMemo(
    () => ({
      currentValue:
        isOnApplication || saleValue === 0 ? '' : saleValue?.toString(),
    }),
    [isOnApplication, saleValue],
  );

  return (
    <>
      <Field
        type="text"
        name={'saleValue'}
        key={'saleValue'}
        validate={(!isOnApplication as any) && priceValidation}
        initialValue={saleValueInitial}
        subscription={{
          value: true,
          active: true,
          error: true,
          dirtySinceLastSubmit: true,
          submitError: true,
          touched: true,
        }}
      >
        {({ input, meta }) => {
          return (
            <CustomFormField>
              <TermsFilter
                id={1}
                name={'saleValue'}
                displayName={'Price'}
                searchQueryGroup={'terms'}
                showLabel={true}
                options={{
                  shouldShowButton: false,
                  prefix:
                    setCurrencyNiOption(initialValues) == true ? '£' : '€',
                }}
                currentValue={isOnApplication ? '' : input.value.currentValue}
                onChange={input.onChange}
                onBlur={input.onBlur}
                inputType={'currency'}
                fakeDisabled={isOnApplication}
              />
              <HandleError meta={meta} name={input.name} />
            </CustomFormField>
          );
        }}
      </Field>
      <Field
        type="checkbox"
        name={'onApplication'}
        key={'onApplication'}
        initialValue={isOnApplication ? true : false}
        subscription={{
          value: true,
          active: true,
          error: true,
          dirtySinceLastSubmit: true,
          touched: true,
          submitError: true,
        }}
      >
        {({ input, meta }) => {
          return (
            <S.FormField>
              <Checkbox
                name={input.name}
                id={input.name}
                onChange={input.onChange}
                checked={input.checked}
                checkboxText={'Price on application'}
                isOptional={false}
                hasError={
                  meta?.touched &&
                  !meta.dirtySinceLastSubmit &&
                  Boolean(meta.error || meta.submitError)
                }
              />
              <OnChange name="onApplication">
                {(value) => {
                  setIsOnApplication(value);
                }}
              </OnChange>
              <Notification>
                <div data-testid="onApplication-notification">
                  We <StyledSpan>strongly advise against</StyledSpan> using POA.
                  It can cause your ad to lose impressions
                </div>
              </Notification>
            </S.FormField>
          );
        }}
      </Field>
      <Field
        type="text"
        name={'currency'}
        key={'currency'}
        initialValue={
          currency && setCurrencyNiOption(initialValues) == true ? 'GBP' : 'EUR'
        }
      >
        {({ input }) => {
          return (
            <input
              type="hidden"
              name={input.name}
              value={input.value}
              onChange={input.onChange}
            />
          );
        }}
      </Field>
    </>
  );
};
