export const formatCurrency = (val: string) => {
  const unformatted =
    val && val !== '' ? val.replace(/[^0-9 ,]/, '').replace(/,/g, '') : '';
  const retVal: string = new Intl.NumberFormat('en-IE').format(
    parseInt(unformatted),
  );
  return isNaN(parseInt(retVal)) ? '' : retVal;
};

export const filterCurrency = (val: string) => {
  const retVal =
    val && val !== '' ? val.replace(/[^0-9 ,]/, '').replace(/,/g, '') : '';
  return isNaN(parseInt(retVal)) ? '' : retVal;
};
