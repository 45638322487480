import styled from 'styled-components';

export const Divider = styled.div`
  padding: 0 0 ${({ theme }) => theme.spacing.M32};
  border-top: 1px solid black;
  border-color: ${({ theme }) => theme.color.GREY_LIGHTER};
`;

export const BedroomTitle = styled.div`
  padding: 0 0 ${({ theme }) => theme.spacing.M24};
  color: ${({ theme }) => theme.color.GREY_DARKER};
  ${({ theme }) => theme.fontSize.P16};
  font-weight: ${({ theme }) => theme.fontWeight.SEMIBOLD};
`;

export const CustomFormField = styled.div<{ spacing: string }>`
  margin-bottom: ${({ spacing, theme }) =>
    spacing === 'narrow' ? theme.spacing.M24 : theme.spacing.M32};
  width: 100%;
`;
