import React, { useState } from 'react';

import styled from 'styled-components';

import {
  AdditionalProps,
  Checkbox,
} from '../../../components/Toolkit/Checkbox/Checkbox';
type CheckboxGroupInputs = AdditionalProps & {
  value?: any;
};

type TCheckboxGroup = {
  checkboxInputs: CheckboxGroupInputs[];
  onChange: (value: any) => void;
  shouldFilterValues?: boolean;
};

const CheckboxWrapper = styled.div`
  :not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.M16};
  }
  label {
    width: 100%;
  }
`;

const CheckboxGroup = ({
  checkboxInputs,
  onChange,
  shouldFilterValues = false,
}: TCheckboxGroup) => {
  const [_, setValues] = useState(
    checkboxInputs.map(
      (checkbox) => checkbox?.value ?? (checkbox.checked || false),
    ),
  );

  const handleChange = (isChecked: boolean, index: number) => {
    setValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = isChecked
        ? checkboxInputs[index].value ?? true
        : false;
      onChange(
        shouldFilterValues
          ? updatedValues.filter((value) => value)
          : updatedValues,
      );
      return updatedValues;
    });
  };

  return (
    <>
      {checkboxInputs.map((props, index) => {
        return (
          <CheckboxWrapper key={index}>
            <Checkbox
              {...props}
              onChange={(value) => handleChange(value, index)}
            />
          </CheckboxWrapper>
        );
      })}
    </>
  );
};

export { CheckboxGroup };
