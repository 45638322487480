import React from 'react';

import BlockyPopOutRange from './BlockyPopOutRange';
import DropDownRange from './DropDownRange';
import PopOutDropDownRange from './PopOutDropDownRange';

declare type Option = {
  value: string;
  displayName: string;
};
declare type OnChangeProps = {
  name?: string;
  displayName?: string;
  values: string[];
  searchQueryGroup?: string;
  filterType: string;
  rangeSide: string;
};
export declare type VariableDropDownRangeProps = {
  variant: string;
  alternativeStyle: boolean;
  name?: string;
  values: Option[];
  id?: string;
  onChange: ({
    name,
    values,
    searchQueryGroup,
    filterType,
    rangeSide,
    displayName,
  }: OnChangeProps) => void;
  className?: string;
  disabled?: boolean;
  currentValue?: {
    values: string[];
  };
  searchQueryGroup?: string;
  rangeSide?: string;
};

const VariableDropDownRange = (props: VariableDropDownRangeProps) => {
  const { variant, ...rest } = props;
  switch (variant) {
    case 'DAFT_POP_OUT':
      return <PopOutDropDownRange {...rest} />;
    case 'DAFT_BLOCKY':
      return <BlockyPopOutRange {...rest} />;
    default:
      if (props.alternativeStyle) {
        return <PopOutDropDownRange {...rest} />;
      }
      return <DropDownRange {...rest} />;
  }
};

export default VariableDropDownRange;
