import React, { ReactNode } from 'react';

import { RadioButtonProps as UnityRadioButtonProps } from '@dsch/form-kit';

import { NewButtonTypes } from '../../../types';
import { FontWeightTypes } from '../../../types/theme';

import * as Styled from './RadioButton.styled';
import { RadioButtonColors } from './RadioButton.types';
import { InputLabel } from '../../Atoms/InputLabel/InputLabel';
import { InputLabelTypes } from '../../Atoms/InputLabel/InputLabel.types';

export type RadioButtonProps = UnityRadioButtonProps & {
  titlePrimaryLabel: string;
  titleSecondaryLabel?: string;
  hasTitleLineBreak?: boolean;
  subtitlePrimaryLabel?: string;
  subtitleFontWeight?: keyof FontWeightTypes;
  subtitleColor?: keyof RadioButtonColors;
  hasError?: boolean;
  buttonType?: NewButtonTypes.PRIMARY | NewButtonTypes.GHOST;
  icon?: ReactNode;
};

export const RadioButton = ({
  value,
  dataTestId,
  titlePrimaryLabel,
  titleSecondaryLabel,
  hasTitleLineBreak,
  subtitleFontWeight = 'REGULAR',
  subtitleColor = 'GREY_DARKER',
  subtitlePrimaryLabel,
  icon,
  buttonType = NewButtonTypes.PRIMARY,
  hasError = false,
  checked = false,
  disabled = false,
  ...props
}: RadioButtonProps) => (
  <Styled.RadioButtonWrapper
    data-testid={`${dataTestId}-div`}
    buttonType={buttonType}
    checked={checked}
    disabled={disabled}
  >
    <Styled.StyledRadioButton
      dataTestId={dataTestId}
      hasError={hasError}
      disabled={disabled}
      checked={checked}
      {...props}
      value={value}
    />

    <Styled.RadioButtonText>
      <InputLabel
        inputPrimaryLabel={titlePrimaryLabel}
        inputSecondaryLabel={titleSecondaryLabel}
        labelSize={InputLabelTypes.S14}
        isDisabled={disabled}
        hasLineBreak={hasTitleLineBreak}
        fontWeight="REGULAR"
        testId="radio-title"
      />
      {subtitlePrimaryLabel && (
        <InputLabel
          inputPrimaryLabel={subtitlePrimaryLabel}
          inputPrimaryLabelColor={subtitleColor}
          fontWeight={subtitleFontWeight}
          labelSize={InputLabelTypes.B12}
          isDisabled={disabled}
          testId="radio-subtitle"
        />
      )}
    </Styled.RadioButtonText>

    {icon && <Styled.IconWrapper>{icon}</Styled.IconWrapper>}
  </Styled.RadioButtonWrapper>
);
